import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import ALL_ROLES from '../../util/RolesEnum';
import { getShallowObjectValues } from '../../util/Utils';

const UserList = ({ data: { users, roles = [] }, mutations: { SetUserRole, DeleteUser } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userForRoleDeletion, setUserForRoleDeletion] = useState(null);
  const setSelectedRoles = useState([]);

  // The modal component that gets rendered when the delete user action is triggered
  const DeleteUserModal = ({ open, handleClose, handleAgree, email }) => (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Are you sure you want to delete this user?</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>Warning: This cannot be reversed.</Typography>
        <Typography variant='body1'>{email}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='secondary'>
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => {
            handleAgree();
            handleClose(false);
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );

  // The modal component that gets rendered when a delete user role action is triggered
  const DeleteRoleModal = ({ open, handleClose, handleAgree, role }) => (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Are you sure you want to delete this role?</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>Warning: This cannot be reversed.</Typography>
        <Typography variant='body1'>{role}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='secondary'>
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => {
            handleAgree();
            handleClose(false);
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );

  // The function which deals with adding or removing user roles
  const handleRoleChange = (newRoles, rowData) => {
    // Ensure newRoles is a valid array. If it's not an array, set it to an empty array.
    newRoles = Boolean(newRoles) && Array.isArray(newRoles) ? newRoles : [];

    // Get the current roles from the user's app_metadata. If not available, use an empty array.
    const currentRoles = rowData.app_metadata.roles || [];

    // Determine which roles are being removed by filtering out roles that are not in the new roles list.
    const rolesToBeRemoved = currentRoles.filter(role => !newRoles.map(r => r.name).includes(role));

    // If there are roles to be removed, open the delete role confirmation dialog.
    if (rolesToBeRemoved.length > 0) {
      // Set the first role to be removed as the selected role for deletion.
      setSelectedRole(rolesToBeRemoved[0]);

      // Set the user data for whom the role is being deleted.
      setUserForRoleDeletion(rowData);

      // Open the delete role confirmation dialog.
      setDeleteRoleDialogOpen(true);
    } else {
      // If no roles are to be removed, update the roles directly.
      updateRoles(newRoles, rowData);
    }
  };
  // This function only deals with user roles being added to a user
  const updateRoles = (newRoles, rowData) => {
    let rolesAndGroups = [];

    if (newRoles.map((r) => r.name).includes(ALL_ROLES.PERMISSION_GROUPS.ALL_USER_PERMISSIONS)) {
      rolesAndGroups = [
        ...getShallowObjectValues(ALL_ROLES.READ),
        ...getShallowObjectValues(ALL_ROLES.WRITE),
      ];
    } else if (newRoles.map((r) => r.name).includes(ALL_ROLES.PERMISSION_GROUPS.ALL_READ_PERMISSIONS)) {
      rolesAndGroups = getShallowObjectValues(ALL_ROLES.READ);
    } else if (newRoles.map((r) => r.name).includes(ALL_ROLES.PERMISSION_GROUPS.ALL_WRITE_PERMISSIONS)) {
      rolesAndGroups = getShallowObjectValues(ALL_ROLES.WRITE);
    } else {
      rolesAndGroups = newRoles ? newRoles.map((role) => role.name) : []; // newRoles can be either an array of strings or null in place of an empty array
    }

    rowData.user_id = rowData.id;

    return SetUserRole({
      variables: {
        userID: rowData.user_id,
        roles: rolesAndGroups,
      },
    }).catch((err) => enqueueSnackbar(`Could not set role! ${rolesAndGroups}`, { variant: 'error' }));
  };

  const handleAgreeToDeleteRole = () => {
    if (userForRoleDeletion) {
      const updatedRoles = userForRoleDeletion.app_metadata.roles.filter(role => role !== selectedRole);
      updateRoles(updatedRoles.map(role => ({ name: role })), userForRoleDeletion);
    }
  };

  const sortedRoles = [...roles].sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <MaterialTable
        title='User Permissions Table'
        data={users.map(user => ({ ...user }))}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => {
              setSelectedUser(rowData);
              setSelectedRoles(rowData.app_metadata.roles || []);
              setDeleteDialogOpen(true);
            },
          },
        ]}
        columns={[
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Permissions',
            field: 'roles',
            render: (rowData = {}) => {
              if (rowData.app_metadata && rowData.app_metadata.roles && Array.isArray(rowData.app_metadata.roles)) {
                if (rowData.app_metadata.roles.length === 1) {
                  return rowData.app_metadata.roles[0];
                } else {
                  return rowData.app_metadata.roles.map((role) => `${role}, `);
                }
              }
              return '';
            },
          },
          {
            title: 'Set Permissions',
            render: (rowData = {}) => {
              return (
                <Select
                  value={
                    rowData.app_metadata && rowData.app_metadata.roles && Array.isArray(rowData.app_metadata.roles)
                      ? rowData.app_metadata.roles.map((role) => ({ name: role }))
                      : []
                  }
                  options={[
                    { name: ALL_ROLES.PERMISSION_GROUPS.ALL_USER_PERMISSIONS },
                    { name: ALL_ROLES.PERMISSION_GROUPS.ALL_READ_PERMISSIONS },
                    { name: ALL_ROLES.PERMISSION_GROUPS.ALL_WRITE_PERMISSIONS },
                    ...sortedRoles,
                  ]}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  onChange={(newRoles) => handleRoleChange(newRoles, rowData)}
                  isMulti
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              );
            },
          },
        ]}
        options={{
          pageSize: Array.isArray(users) ? users.length : 0,
          actionsColumnIndex: -1,
        }}
      />
      <DeleteUserModal
        open={deleteDialogOpen}
        handleClose={setDeleteDialogOpen}
        handleAgree={() => {
          if (selectedUser) {
            const { email, user_id } = selectedUser;
            DeleteUser({
              variables: {
                userID: user_id,
              },
            })
              .then((res) => {
                enqueueSnackbar(`Successfully deleted ${email} from system!`, { variant: 'success' });
              })
              .catch((err) => {
                enqueueSnackbar(`Error: ${err.message}`);
              });
          }
        }}
        email={selectedUser ? selectedUser.email : ''}
      />
      <DeleteRoleModal
        open={deleteRoleDialogOpen}
        handleClose={setDeleteRoleDialogOpen}
        handleAgree={handleAgreeToDeleteRole}
        role={selectedRole}
      />
    </>
  );
};

export default UserList;

import React from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import {FieldArray, Form, Formik} from 'formik';
import {TextField} from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment';
import gql from 'graphql-tag';
import {useMutation, useQuery, useLazyQuery} from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import {
    CREATE_LEASE_RECORDING,
    DELETE_LEASE_RECORDING,
    UPDATE_LEASE_RECORDING,
} from '../../components/leases/LeaseQueries';
import {FILE_QUERY} from '../../components/files/fileQueries';
import {LeaseTableStyles} from './EditLease';
import FileUploadModal from "../../components/files/FileUploadModal";


const LEASE_RECORDINGS_QUERY = gql`
    query Lease($id: ID) {
        Lease(id: $id) {
            id
            leaseName
            leasedLands {
                id
                netMineralAcres
                netSurfaceAcres
                depths
                shortDescription
                Tract {
                    id
                }
            }
            leaseRecordings {
                id
                volPage
                book
                county
                effectiveDate
                dateFiled
                documentName
                notes
                totalAcres
                fileName
                grantor {
                    id
                    name
                }
                grantee {
                    id
                    name
                }
                recordingInterest {
                    id
                    acres
                    Tract {
                        id
                    }
                }
            }
        }
    }
`;

const TABLE_OPERATORS = gql`
    query {
        Operator {
            id
            name
        }
    }
`;

const countyOptions = [
    {type: 'Arapahoe'},
    {type: 'Brewster'},
    {type: 'Chautauqua'},
    {type: 'Cherokee'},
    {type: 'Cheyenne'},
    {type: 'Culberson'},
    {type: 'Greeley'},
    {type: 'Kearny'},
    {type: 'Lea'},
    {type: 'Lincoln'},
    {type: 'Ward'},
    {type: 'Pecos'},
    {type: 'Reeves'},
    {type: 'SantaFe'},
    {type: 'Saguache'},
    {type: 'Trego'},
];

export const RecordingsTable = ({match}) => {
    const classes = LeaseTableStyles();
    const [loading, setLoading] = React.useState(false);
    const [addLeaseRecording] = useMutation(CREATE_LEASE_RECORDING);
    const [deleteRecording] = useMutation(DELETE_LEASE_RECORDING);
    const [editRecording] = useMutation(UPDATE_LEASE_RECORDING);
    const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
    const [fileKey, setFileKey] = React.useState('');
    const [rowInfo, setRowInfo] = React.useState({});
    const [loadFile, {data: fileData, loading: fileLoading, error: fileError}] = useLazyQuery(FILE_QUERY,
        {variables: {fileName: fileKey}});

    const {data, loading: leaseLoading, error} = useQuery(LEASE_RECORDINGS_QUERY, {
        variables: {id: match.params.leaseID},
    });

    const {data: operatorData, loading: operatorLoading, error: operatorError} = useQuery(TABLE_OPERATORS)

    if (loading || operatorLoading || leaseLoading) {
        return <LoadingView/>;
    }
    if (error || operatorError) {
        return error.toString();
    }

    const Lease = data.Lease[0] || [];

    const Operators = operatorData.Operator || [];

    const {leaseRecordings} = Lease;

    //Capture Leased Lands for use in recordingment Mutation
    const lands = Lease.leasedLands.map((land) => land.Tract.id);

    // Add the lands on the table
    const tryLands = Lease.leasedLands.map((Land) => {
        return {
            title: `${Land.Tract.id} Acres`,
            field: `${Land.Tract.id}`,
            type: 'numeric',
            editable: 'onAdd',
        };
    });

    //create empty array to handle form data

    const finalData = [];

    if (Array.isArray(leaseRecordings) || leaseRecordings.length > 0) {
        // loop through each recording
        leaseRecordings.forEach((recording) => {
            //create the new data object
            const dataObj = {
                id: recording.id,
                book: recording.book,
                volPage: recording.volPage,
                dateFiled: recording.dateFiled,
                effectiveDate: recording.effectiveDate,
                totalAcres: !recording.totalAcres ? 0 : recording.totalAcres,
                grantee: !recording.grantee ? 'Null' : recording.grantee.name,
                grantor: !recording.grantor ? 'Null' : recording.grantor.name,
                county: recording.county,
                documentName: recording.documentName,
                notes: recording.notes,
                fileName: recording.fileName ? recording.fileName : '',
            };

            delete dataObj.recordings;

            recording.recordingInterest.forEach((assn) => {
                //loop through the recordings to capture all of the tract ids, and acres
                dataObj[assn.Tract.id] = assn.acres;
            });
            //push data onto empty array
            finalData.push(dataObj);
        });
    }

    const formikData = {
        finalData,
    };

    return (
        <>
            <Formik initialValues={formikData} enableReinitialize={true} onSubmit={() => {
            }}>
                {(props) => {
                    return (
                        <Form className={classes.form}>
                            <Typography className={classes.title} color='textSecondary' gutterBottom>
                                Chain of Title
                            </Typography>
                            <FieldArray name='finalData'>
                                {({push, remove, insert}) => {
                                    const columns = [
                                        {
                                            title: 'Vol/Page',
                                            field: 'volPage',
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                        {
                                            title: 'Book',
                                            field: 'book',
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                        {
                                            title: 'County',
                                            field: 'county',
                                            editComponent: ({onChange}) => (
                                                <Select
                                                    options={countyOptions}
                                                    getOptionLabel={(option) => option.type}
                                                    getOptionValue={(option) => option.type}
                                                    onChange={({type}) => onChange(type)}
                                                />
                                            ),
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                                textAlign: 'left',
                                            },
                                        },
                                        {
                                            title: 'Document Name',
                                            field: 'documentName',
                                        },
                                        {
                                            title: 'Effective Date',
                                            field: 'effectiveDate',
                                            render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                                            customSort: (a, b) => (moment(a.effectiveDate).isAfter(moment(b.effectiveDate)) ? 1 : -1),
                                            editComponent: (props) => (
                                                <TextField type='date'
                                                           onChange={(e) => props.onChange(e.target.value)}/>
                                            ),
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                        {
                                            title: 'Date Filed',
                                            field: 'dateFiled',
                                            render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                                            customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                                            editComponent: (props) => (
                                                <TextField type='date'
                                                           onChange={(e) => props.onChange(e.target.value)}/>
                                            ),
                                            type: 'date',
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                        {
                                            title: 'Grantor',
                                            field: 'grantor',
                                            editable: 'onAdd',
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                            editComponent: ({onChange}) => (
                                                <Select
                                                    options={Operators}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={onChange}
                                                    styles={{
                                                        menu: (provided, state) => ({...provided, width: 150}),
                                                        menuList: (provided, state) => ({...provided, width: 150}),
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Grantee',
                                            field: 'grantee',
                                            editable: 'onAdd',
                                            cellStyle: {
                                                whiteSpace: 'nowrap',
                                            },
                                            editComponent: ({onChange}) => (
                                                <Select
                                                    options={Operators}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={onChange}
                                                    styles={{
                                                        menu: (provided, state) => ({...provided, width: 150}),
                                                        menuList: (provided, state) => ({...provided, width: 150}),
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Notes',
                                            field: 'notes',
                                        },
                                        ...tryLands,
                                        {
                                            title: 'Total Acres',
                                            field: 'totalAcres',
                                            render: (rowData) => (!rowData ? '0' : rowData.totalAcres.toFixed(8)),
                                            editable: 'never',
                                            cellStyle: {
                                                whiteSpace: 'Normal',
                                                textAlign: 'left',
                                            },
                                        },
                                    ];

                                    return (
                                        <MaterialTable
                                            columns={columns}
                                            data={props.values.finalData}
                                            editable={{
                                                onRowAdd: (newData) => {
                                                    const landsAssigned = lands
                                                        .filter((land) => newData[land] && newData[land] !== '0')
                                                        .map((land) => ({
                                                            TractID: land,
                                                            acres: !newData[land] ? null : newData[land],
                                                        }));
                                                    return addLeaseRecording({
                                                        variables: {
                                                            leaseID: Lease.id,
                                                            landIDs: landsAssigned,
                                                            RecordingInfo: {
                                                                volPage: !newData.volPage ? 'N/A' : newData.volPage,
                                                                book: !newData.book ? 'N/A' : newData.book,
                                                                county: newData.county,
                                                                notes: !newData.notes ? 'None' : newData.notes,
                                                                effectiveDate: newData.effectiveDate,
                                                                dateFiled: newData.dateFiled,
                                                                grantor: newData.grantor.id,
                                                                grantee: newData.grantee.id,
                                                                documentName: !newData.documentName ? 'None' : newData.documentName,
                                                            },
                                                        },
                                                    })
                                                        .then((data) => {
                                                            const update = data.data.createLeaseRecording.leaseRecordings[0];
                                                            const pushData = {
                                                                ...newData,
                                                                grantor: update.grantor.name,
                                                                grantee: update.grantee.name,
                                                                id: update.id,
                                                                totalAcres: update.totalAcres,
                                                            };
                                                            push(pushData);
                                                        })
                                                        .catch((error) => console.log(error));
                                                },
                                                onRowUpdate: (newData, oldData) => {
                                                    return editRecording({
                                                        variables: {
                                                            id: oldData.id,
                                                            volPage: !newData.volPage ? oldData.volPage : newData.volPage,
                                                            book: !newData.book ? oldData.book : newData.book,
                                                            county: !newData.county ? oldData.county : newData.county,
                                                            effectiveDate: !newData.effectiveDate ? oldData.effectiveDate : newData.effectiveDate,
                                                            dateFiled: !newData.dateFiled ? oldData.dateFiled : newData.dateFiled,
                                                            documentName: !newData.documentName ? oldData.documentName : newData.documentName,
                                                            notes: !newData.notes ? oldData.notes : newData.notes,
                                                        },
                                                    }).then(({data: {UpdateRecording}}) => {
                                                        remove(oldData.tableData.id);
                                                        insert(oldData.tableData.id, UpdateRecording);
                                                    });
                                                },
                                                onRowDelete: (oldData) => {
                                                    return deleteRecording({
                                                        variables: {
                                                            leaseID: Lease.id,
                                                            recordingID: oldData.id,
                                                        },
                                                    })
                                                        .then(() => remove(oldData.tableData.id))
                                                        .catch((error) => console.log(error));
                                                },
                                            }}
                                            actions={
                                                [
                                                    {
                                                        icon: 'addFile',
                                                        tooltip: 'Add File',
                                                        onClick: (event, rowData, tableData) => {
                                                            const {id} = rowData;
                                                            const leaseID = match.params.leaseID;
                                                            const fileName = `${leaseID}/${id}`;
                                                            setFileKey(fileName);
                                                            setRowInfo(rowData);
                                                            setFileUploadOpen(!fileUploadOpen);
                                                        }
                                                    },
                                                    {
                                                        icon: 'map',
                                                        tooltip: 'View File',
                                                        onClick: async (event, rowData) => {
                                                            const {id} = rowData;
                                                            const leaseID = match.params.leaseID;
                                                            const fileName = `${leaseID}/${id}`;
                                                            setFileKey(rowData.fileName ? rowData.fileName : fileName);
                                                            try {
                                                                const response = await loadFile();
                                                                const {data: {getFile}} = response;
                                                                const url = getFile[0].requestUrl;
                                                                const link = document.createElement('a');
                                                                link.href = url;
                                                                link.click();
                                                            } catch (e) {
                                                                console.log(e);
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#1976d2',
                                                    color: '#FFF',
                                                    textAlign: 'left',
                                                },
                                                cellStyle: {
                                                    maxWidth: 0,
                                                    overFlowX: 'auto',
                                                },
                                                exportButton: true,
                                                addRowPosition: 'first',
                                                searchFieldAlignment: 'left',
                                                toolbarButtonAlignment: 'left',
                                                pageSize: 20,
                                            }}
                                            title={''}
                                        />
                                    );
                                }}
                            </FieldArray>
                            <FileUploadModal
                                open={fileUploadOpen}
                                setFileUploadOpen={setFileUploadOpen}
                                fileKey={fileKey}
                                recordUpdate={editRecording}
                                rowInfo={rowInfo}
                                values={props.values}
                                setFieldValue={props.setFieldValue}
                                uploadType={'LAND'}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default RecordingsTable;

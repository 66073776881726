import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { TRACTS_TABLE_QUERY } from '../../components/tracts/TractQueries';
import LoadingView from '../../components/LoadingView';
import { getPageNumberFromQueryParams } from '../../util/Utils';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/styles';

// Helper to map color codes
function getColorStyle(color) {
  switch (color) {
    case 'M&S':
      return { backgroundColor: 'rgba(255,255,0,0.53)', color: 'black' };
    case 'M':
      return { backgroundColor: 'rgba(255,0,0,0.2)', color: 'black' };
    case 'S':
      return { backgroundColor: 'rgba(0,0,255,0.2)', color: 'black' };
    case 'All':
      return { backgroundColor: 'rgba(0,200,255,0.2)', color: 'black' };
    default:
      return { };
  }
}
const anyStyles = makeStyles(() => ({
  buttons: {
    cursor: 'pointer',
    color: 'black',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
  },
}));


// Parse fractional strings like "3/8", "1/4", or "1"
function fractionToNumber(fracStr) {
  if (!fracStr) return 0;
  if (fracStr.includes('/')) {
    const [numerator, denominator] = fracStr.split('/');
    return parseFloat(numerator) / parseFloat(denominator);
  }
  return parseFloat(fracStr);
}

// Columns: one row = one mineralsOwned entry + top-level fields
const LandsColumns = [
  {
    title: 'FAMILY ID',
    field: 'id', // searching on the `id` property
    render: (rowData) => (
      <Link
        to={`/Lands/${rowData.id}`}
        target="_blank"
        style={getColorStyle(rowData.color)}
      >
        {rowData.id}
      </Link>
    ),
  },
  {
    title: 'TYPE',
    field: 'color',
  },
  {
    title: 'NMA',
    field: 'familyNMA',
    render: (rowData) =>
      rowData.familyNMA === '0'
        ? '0'
        : parseFloat(rowData.familyNMA).toFixed(8),
  },
  {
    title: 'NSA',
    field: 'familyNSA',
    render: (rowData) =>
      rowData.familyNSA === '0'
        ? '0'
        : parseFloat(rowData.familyNSA).toFixed(8),
  },
  {
    title: 'MES %',
    field: 'mesPercent',
    render: (rowData) => {
      // netMineralAcres from mineralsOwned
      // grossMineralAcres from top-level
      const net = parseFloat(rowData.familyNMA) || 0;
      const gross = parseFloat(rowData.grossMineralAcres) || 0;
      // const netOwn = parseFloat(rowData.netMineralAcres) || 0;
      // console.log(netOwn, net,"/", gross, "* 100 , NET MIN AND GROSS MIN")
      if (gross === 0) return '0.00';
      return ((net / gross) * 100).toFixed(2);
    },
  },
  {
    title: 'MPI %',
    field: 'mpiPercent',
    render: (rowData) => {
      // mineralFraction from mineralsOwned
      const fraction = fractionToNumber(rowData.mineralFraction);
      return (fraction * 100).toFixed(2);
    },
  },
  {
    title: 'SRO %',
    field: 'sroPercent',
    render: (rowData) => {
      // Optionally, if you want the top-level surface
      // (Though we’re focusing on minerals, we included it for completeness.)
      const net = parseFloat(rowData.familyNSA) || 0;
      const gross = parseFloat(rowData.grossSurfaceAcres) || 0;
      if (gross === 0) return '0.00';
      return ((net / gross) * 100).toFixed(2);
    },
  },
];

function LandsTable({ history }) {
  const classes = anyStyles(undefined);
  const { loading, error, data } = useQuery(TRACTS_TABLE_QUERY);
  const [selectedColor, setSelectedColor] = useState(null); // default filter

  const [, setPageNumber] = useState(
    getPageNumberFromQueryParams(queryString.parse(window.location.search))
  );

  const tableRef = useRef(null);

  // Make sure pageSize is at least 1
  const calculatedPageSize = Math.max(
    1,
    Math.floor((window.innerHeight - 300) / 40)
  );

  useEffect(() => {
    if (tableRef.current?.dataManager) {
      tableRef.current.dataManager.changePageSize(calculatedPageSize);
    }
  }, [calculatedPageSize]);

  if (error) return <h1>Can’t Load Tracts!</h1>;
  if (loading) return <LoadingView />;

  const allTracts = data.Tract;
  const countAll = allTracts.length;
  const countMS = allTracts.filter(t => t.color === 'M&S').length;
  const countS  = allTracts.filter(t => t.color === 'S').length;
  const countM  = allTracts.filter(t => t.color === 'M').length;

  // 1) Filter the top-level array by color (M, S, or M&S)
  const filteredTracts =
    selectedColor
      ? data.Tract.filter(t => t.color === selectedColor)
      : data.Tract;

// Flatten the mineralsOwned
  const tableData = filteredTracts.flatMap((tract) =>
    tract.mineralsOwned.map((owner) => ({
      id: tract.id,
      color: tract.color,
      familyNMA: tract.familyNMA,
      familyNSA: tract.familyNSA,
      grossMineralAcres: tract.grossMineralAcres,
      grossSurfaceAcres: tract.grossSurfaceAcres,
      netMineralAcres: owner.netMineralAcres,
      mineralFraction: owner.mineralFraction,
    }))
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>

      {/* RIGHT SIDE: MaterialTable with the flattened data */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <MaterialTable
          tableRef={tableRef}
          data={tableData}
          columns={LandsColumns}
          title=""
          onChangePage={(page) => setPageNumber(page)}
          options={{
            tableLayout: 'auto',
            sorting: true,
            headerStyle: {
              backgroundColor: '#1976d2',
              color: '#fff',
            },
            pageSize: calculatedPageSize,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              marginLeft: '-24px',
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: '#fff',
              color: '#000',
            },
            toolbarButtonAlignment: 'left',
            maxBodyHeight: 'calc(100vh - 150px)',
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: '#1976d2',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  paddingBottom: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MTableToolbar {...props} />
                <button
                  onClick={() => setSelectedColor(null)}
                  style={{
                    backgroundColor: 'rgb(189,189,189)',
                    marginRight: '25px',
                    marginLeft: '-630px',
                  }}
                  className={`${classes.buttons} btn btn-sm fw-bold`}
                >
                  ALL ({countAll})
                </button>
                <button
                  onClick={() => setSelectedColor('M&S')}
                  style={{
                    backgroundColor: 'rgb(245,240,36)',
                    marginRight: '15px',
                    marginLeft: '-630px',
                  }}
                  className={`${classes.buttons} btn btn-sm fw-bold`}
                >
                  M&S ({countMS})
                </button>
                <button
                  onClick={() => setSelectedColor('S')}
                  style={{
                    backgroundColor: 'rgb(177,156,224)',
                    marginRight: '25px',
                    marginLeft: '-620px',
                    paddingLeft: '20px',
                  }}
                  className={`${classes.buttons} btn btn-sm fw-bold`}
                >
                  S ({countS})
                </button>
                <button
                  onClick={() => setSelectedColor('M')}
                  style={{
                    backgroundColor: 'rgb(245,195,195)',
                    marginLeft: '-630px',
                    paddingLeft: '20px',
                  }}
                  className={`${classes.buttons} btn btn-sm fw-bold`}
                >
                  M ({countM})
                </button>
                <button
                  onClick={() => history.push('/addLand')}
                  style={{
                    marginRight: '16px',
                    border: 'none',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    color: '#000',
                  }}
                >
                  Add Land
                </button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default LandsTable;

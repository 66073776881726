import React from 'react';
import {Button, Card, CardContent, CardHeader, CircularProgress, Modal, Paper} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import {useSnackbar} from "notistack";
import {useMutation} from "@apollo/client";
import {UPLOAD_FILE} from "./fileQueries";

function isArrayElementReadOnly(array, index) {
    const descriptor = Object.getOwnPropertyDescriptor(array, index);
    return descriptor && descriptor.writable === false;
}

const FileUploadModal = ({
                             open,
                             setFileUploadOpen,
                             recordUpdate,
                             fileKey,
                             setFieldValue,
                             values,
                             rowInfo,
                             uploadType,
                         }) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false);
    const [mutate] = useMutation(UPLOAD_FILE);

    return (
        <Modal
            open={open}
            onClose={() => setFileUploadOpen(!open)}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            <Fade in={open}>
                <Paper
                    style={{
                        position: 'absolute',
                        top: '30%',
                        left: '40%',
                        width: 400,
                        // height: 400,
                    }}
                >
                    <Card>
                        <CardContent>
                            <CardHeader title="File Upload"/>
                            <hr/>
                            <div>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    {
                                        isLoading ? <CircularProgress /> : 'Upload File'
                                    }
                                    <input
                                        type="file"
                                        accept="image/*, application/pdf"
                                        hidden
                                        onChange={async ({target: {validity, files: [file]}}) => {
                                            let updatedRecording = {};
                                            if (validity.valid) {
                                                try {
                                                    setIsLoading(true);
                                                    const fileExtension = file.name.split('.').pop();
                                                    const uploadName = `${fileKey}.${fileExtension}`;
                                                    await mutate({
                                                        variables: {
                                                            file,
                                                            fileSize: `${file.size}`,
                                                            name: uploadName
                                                        }
                                                    });
                                                    if (uploadType === 'LAND') {
                                                        updatedRecording = await recordUpdate({
                                                            variables: {
                                                                ...rowInfo,
                                                                fileName: uploadName
                                                            }
                                                        });
                                                    } else if(uploadType === 'ROW') {
                                                        const {id, volPage, effectiveDate, dateFiled, comments, grantor, grantee} = rowInfo
                                                        updatedRecording = await recordUpdate({
                                                            variables: {
                                                                rowinfo: {
                                                                    volPage,
                                                                    effectiveDate,
                                                                    dateFiled,
                                                                    comments,
                                                                    grantor,
                                                                    grantee,
                                                                    fileName: uploadName
                                                                },
                                                                ROWTitleID: rowInfo.id
                                                            }
                                                        });
                                                    } else if(uploadType === 'UNIT') {
                                                        const {
                                                            volPage,
                                                            effectiveDate,
                                                            dateFiled,
                                                            comments,
                                                            grantor,
                                                            grantee,
                                                            docType,
                                                        } = rowInfo
                                                        const response = await recordUpdate({
                                                            variables: {
                                                                info: {
                                                                    volPage,
                                                                    effectiveDate,
                                                                    dateFiled,
                                                                    comments,
                                                                    grantor,
                                                                    grantee,
                                                                    docType,
                                                                    fileName: uploadName
                                                                },
                                                                UnitTitleID: rowInfo.id
                                                            }
                                                        })

                                                        const { data: {updateUnitTitle: titleRun} } = response;
                                                        updatedRecording = titleRun.titleRun[0]
                                                    } else if(uploadType === 'TITLE') {
                                                        const response = await recordUpdate({
                                                            variables: {
                                                                ...rowInfo, fileName: uploadName
                                                            }
                                                        })
                                                        const { data: {UpdateLandTitle: titleRun} } = response;
                                                        updatedRecording = titleRun
                                                    }

                                                    const {finalData} = values;
                                                    // find the index of the row in finalData that has the same id as the info in rowData and remove it
                                                    const index = finalData.findIndex(({id}) => id === rowInfo.id);
                                                    if (isArrayElementReadOnly(finalData, index)) {
                                                        const updatedFormData = [
                                                            ...finalData.slice(0, index),
                                                            updatedRecording,
                                                            ...finalData.slice(index + 1)
                                                        ];

                                                        setFieldValue('finalData', updatedFormData);
                                                    } else {
                                                        // replace that row with the updatedRecording
                                                        const updatedFormData = finalData.splice(index, 1, updatedRecording);
                                                        setFieldValue('finalData', updatedFormData);
                                                    }
                                                    enqueueSnackbar('File Successfully Uploaded', {variant: 'success'});
                                                } catch (e) {
                                                    enqueueSnackbar('File Upload Failed', {variant: 'error'});
                                                    console.log(e)
                                                } finally {
                                                    setFileUploadOpen(!open)
                                                    setIsLoading(false);
                                                    // wait1 second, then close snackbar
                                                    setTimeout(() => {
                                                        closeSnackbar();
                                                    }, 1000);
                                                }
                                            }
                                        }
                                        }
                                    />
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
            </Fade>
        </Modal>

    );
};

export default FileUploadModal;
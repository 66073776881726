import React from 'react';
import LoadingView from '../../components/LoadingView';
import { GET_INPROGRESS_WELLS } from '../../components/wells/WellQueries';
import { Button, Typography } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import MaterialTable from 'material-table';
import { CSVLink } from 'react-csv';

const InProgressWells = () => {
  // const [wellData, setWellData] = React.useState([]);

  return (
    <Query query={GET_INPROGRESS_WELLS}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);
          return <Typography variant='h5'>{error.toString()}</Typography>;
        } else if (loading) {
          return <LoadingView />;
        }

        const Wells = data.Well;

        const headers = [
          { label: 'Well Name', key: 'name' },
          { label: 'API #', key: 'apiNumber' },
          { label: 'RRC Lease', key: 'rrcLeaseNumber' },
          { label: 'Permit', key: 'permitNumber' },
          { label: 'Land Id', key: 'landId' },
          { label: 'Abstract', key: 'abstract' },
          { label: 'Status', key: 'status' },
          { label: 'Spud Date', key: 'spudDate' },
          { label: 'Rig Release', key: 'rigReleaseDate' },
          { label: 'Date Producing', key: 'dateProducing' },
          { label: 'Date In Pay', key: 'dateInPay' },
          { label: 'OGL', key: 'OGL' },
          { label: 'Location', key: 'location' },
          { label: 'DecProdUnit', key: 'decProdUnit' },
          { label: 'Producing Formation', key: 'producingFormation' },
          { label: 'Length of Lateral', key: 'lengthOfLateral' },
          { label: 'RRC Field', key: 'rrcField' },
          { label: 'Oil Payor', key: 'oilPayor' },
          { label: 'Gas Payor', key: 'gasPayor' },
          { label: 'Operator', key: 'operator' },
          { label: 'Family ID', key: 'familyID' },
          { label: 'MES DOI', key: 'mesDOI' },
          { label: 'MPI DOI', key: 'mpiDOI' },
          { label: 'SRO DOI', key: 'sroDOI' },
          { label: 'Comments', key: 'notes' },
          { label: 'RRC Lease Notes', key: 'rrcLeaseHistory' },
          { label: 'Operator History', key: 'operatorHistory' },
          { label: 'Unit', key: 'unit' },
        ];

        const wellData = Wells.map((w) => {
          const mesDOI = !w.divisionOrder[0] ? 'Incomplete' : w.divisionOrder[0].mesDOI.toFixed(8).toString();
          const mpiDOI = !w.divisionOrder[0] ? 'Incomplete' : w.divisionOrder[0].mpiDOI.toFixed(8).toString();
          const sroDOI = !w.divisionOrder[0] ? 'Incomplete' : w.divisionOrder[0].sroDOI.toFixed(8).toString();

          return {
            name: w.name,
            apiNumber: w.apiNumber,
            rrcLeaseNumber: w.rrcLeaseNumber,
            permitNumber: w.permitNumber,
            landId: w.drilledOn.map((e) => e.id).join(', '),
            abstract: w.abstract,
            status: w.status,
            spudDate: w.spudDate,
            rigReleaseDate: w.rigReleaseDate,
            dateProducing: w.dateProducing,
            dateInPay: w.dateInPay,
            OGL: w.OGL,
            location: w.location,
            decProdUnit: w.decProdUnit,
            producingFormation: w.producingFormation,
            lengthOfLateral: w.lengthOfLateral,
            rrcField: w.rrcField,
            oilPayor: w.oilPayor,
            gasPayor: w.gasPayor,
            operator: w.operator,
            familyId: w.familyId,
            mesDOI: mesDOI,
            mpiDOI: mpiDOI,
            sroDOI: sroDOI,
            comments: w.notes,
            rrcLeaseHistory: w.rrcLeaseHistory,
            operatorHistory: w.operatorHistory,
            unit: !w.unit ? '' : w.unit.name,
          };
        });

        const columns = [
          {
            title: 'Well Name',
            field: 'name',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'API #',
            field: 'apiNumber',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'RRC Lease',
            field: 'rrcLeaseNumber',
          },
          {
            title: 'Permit',
            field: 'permitNumber',
          },
          {
            title: 'Land Id',
            render: (rowData) => rowData.drilledOn.map((e) => e.id).join(', '),
            field: 'landId',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Abstract',
            field: 'abstract',
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            title: 'Spud Date',
            field: 'spudDate',
          },
          {
            title: 'Rig Release',
            field: 'rigReleaseDate',
          },
          {
            title: 'Date Producing',
            field: 'dateProducing',
          },
          {
            title: 'Date In Pay',
            field: 'dateInPay',
          },
          {
            title: 'OGL',
            field: 'OGL',
          },
          {
            title: 'Location',
            field: 'location',
          },
          {
            title: 'DecProdUnit',
            field: 'decProdUnit',
          },
          {
            title: 'DPU',
            field: 'dpu',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Producing Formation',
            field: 'producingFormation',
          },
          {
            title: 'Length of Lateral',
            field: 'lengthOfLateral',
          },
          {
            title: 'RRC Field',
            field: 'rrcField',
          },
          {
            title: 'Oil Payor',
            field: 'oilPayor',
          },
          {
            title: 'Gas Payor',
            field: 'gasPayor',
          },
          {
            title: 'Operator',
            field: 'operator',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Family ID',
            field: 'familyID',
          },
          {
            title: 'MES DOI',
            render: (rowData) => {
              if (rowData.divisionOrder[0]) {
                let data = rowData.divisionOrder[0];
                let mesDOI = !data.mesDOI ? 'Incomplete' : data.mesDOI.toFixed(8).toString();
                return mesDOI;
              }
            },
            field: 'divisionOrder[0].mesDOI',
          },
          {
            title: 'MPI DOI',
            render: (rowData) => {
              if (rowData.divisionOrder[0]) {
                let data = rowData.divisionOrder[0];
                let mpiDOI = !data.mpiDOI ? 'Incomplete' : data.mpiDOI.toFixed(8).toString();
                return mpiDOI;
              }
            },
            field: 'divisionOrder[0].mpiDOI',
          },
          {
            title: 'SRO DOI',
            render: (rowData) => {
              if (rowData.divisionOrder[0]) {
                let data = rowData.divisionOrder[0];
                let sroDOI = !data.mpiDOI ? 'Incomplete' : data.sroDOI.toFixed(8).toString();
                return sroDOI;
              }
            },
            field: 'sroDOI',
          },
          {
            title: 'Comments',
            field: 'notes',
          },
          {
            title: 'RRC Lease Notes',
            field: 'rrcLeaseHistory',
          },
          {
            title: 'Operator History',
            field: 'operatorHistory',
          },
          {
            title: 'Unit',
            field: 'unit.name',
          },
        ];
        return (
          <div>
            <CSVLink data={wellData} headers={headers}>
              <Button variant='contained' color='primary' style={{ marginBottom: 10 }}>
                Download CSV
              </Button>
            </CSVLink>
            <MaterialTable
              data={Wells.map(w => ({...w}))}
              columns={columns}
              title={'In Progress Wells'}
              options={{
                headerStyle: {
                  backgroundColor: '#1976d2',
                  color: '#FFF',
                  textAlign: 'center',
                  justifyContent: 'center',
                  whiteSpace: 'pre',
                },
                selection: false,
                exportButton: false,
                pageSize: wellData.length === 0 ? Wells.length : wellData.length,
                searchFieldAlignment: 'left',
                toolbarButtonAlignment: 'left',
              }}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default InProgressWells;

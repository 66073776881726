import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import UserList from './UserList';
import { USERS_LIST, SET_ROLE, ROLE_LIST, CREATE_USER, DELETE_USER, ADD_STATE, STATE_LIST, UPDATE_STATE, DELETE_STATE, DELETE_COUNTY } from '../../components/admin/adminQueries';
import LoadingView from '../../components/LoadingView';
import CreateUserForm from './CreateUserForm';
import Settings from './AdminSettings';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Whenever an action on a user is performed from the admin panel this is where the apollo/graphql query starts
const AdminPage = (props) => {
  const UserListQuery = useQuery(USERS_LIST);
  const UserRoleQuery = useQuery(ROLE_LIST);
  const StateListQuery = useQuery(STATE_LIST);
  const [DeleteState] = useMutation(DELETE_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const [DeleteCounty] = useMutation(DELETE_COUNTY, { refetchQueries: [{ query: STATE_LIST }] });
  const [SetUserRole] = useMutation(SET_ROLE, { refetchQueries: [{ query: USERS_LIST }] });
  const [CreateUser] = useMutation(CREATE_USER, { refetchQueries: [{ query: USERS_LIST }] });
  const [DeleteUser] = useMutation(DELETE_USER, { refetchQueries: [{ query: USERS_LIST }] });
  const [AddState] = useMutation(ADD_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const [UpdateState] = useMutation(UPDATE_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState(0);

  if (UserListQuery.error) {
    return <h1>Could not load users!</h1>;
  } else if (UserListQuery.loading) {
    return <LoadingView />;
  }

  return (
    <div>
      <AppBar position='static'>
        <Tabs value={currentTab} onChange={(e, tabIndex) => setCurrentTab(tabIndex)}>
          <Tab label='Users' />
          <Tab label='Create User' />
          <Tab label='Settings' />
        </Tabs>
      </AppBar>
      {currentTab === 0 && (
        <UserList
          data={{
            users: UserListQuery.data.Users,
            roles: UserRoleQuery.data.__type.enumValues || [],
          }}
          mutations={{ SetUserRole, DeleteUser }}
        />
      )}
      {currentTab === 1 && <CreateUserForm enqueueSnackbar={enqueueSnackbar} mutations={{ CreateUser }} />}
      {currentTab === 2 && <Settings enqueueSnackbar={enqueueSnackbar}
                                     data={{states: StateListQuery.data.states, fullData: StateListQuery.data}}
                                     mutations={{ AddState, UpdateState, DeleteState, DeleteCounty }} />}
    </div>
  );
};

export default AdminPage;

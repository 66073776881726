import React from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import Link from 'react-router-dom/Link';
import {Switch, TextField} from 'formik-material-ui';
import {useSnackbar} from 'notistack';
import Auth from '../../util/Auth';
import {Button, InputLabel, MenuItem} from '@material-ui/core';
import MaterialTable from 'material-table';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Iframe from 'react-iframe';
import {UnitSchema} from '../../components/units/UnitQueries';
import DeleteUnitModal from './DeleteUnitModal';
import CreateableSelect from 'react-select/creatable';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import _ from 'lodash';
import {unitStatuses} from "../../util/Statuses";
import {FormStyles} from "./UnitTabs";

const OperatorQuery = gql`
    query {
        Operator {
            id
            name
        }
    }
`;

const WELL_INFO = gql`
    query {
        Well {
            id
            name
        }
    }
`;

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_UNITS);

const tableOptions = {
    paging: false,
    search: false,
    toolbarButtonAlignment: 'left',
    headerStyle: {
        backgroundColor: '#1976d2',
        color: '#FFF',
        textAlign: 'left',
        justifyContent: 'center',
        whiteSpace: 'pre',
    },
};

const UnitDetailPage = ({Unit, leaseIDs, updateUnit, deleteUnit, history, addWell, removeWell}) => {
    const classes = FormStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [modalOpen, setModalOpen] = React.useState(false);

    const {data, loading, error} = useQuery(OperatorQuery);
    const {data: wellData, loading: wellLoading, error: wellError } = useQuery(WELL_INFO);

    const divisionOrderComplete =
        Unit.wells.length === 0 ? false : Unit.wells[0].divisionOrder.length === Unit.wells[0].drilledOn.length;

    const totalDOI = (ownerName = '') => {
        return Unit.wells[0].divisionOrder.reduce(function (a, v) {
            return a + v[ownerName];
        }, 0);
    };

    const mesDOI = divisionOrderComplete ? totalDOI('mesDOI').toFixed(8) : 'Incomplete';
    const sroDOI = divisionOrderComplete ? totalDOI('sroDOI').toFixed(8) : 'Incomplete';
    const mpiDOI = divisionOrderComplete ? totalDOI('mpiDOI').toFixed(8) : 'Incomplete';

    if (loading || wellLoading) {
        return <LoadingView/>;
    }

    if (error || wellError) {
        return error.toString();
    }

    const Operators = data.Operator || [];
    const wells = wellData.Well || [];

    //Create a unique set for lands and OGLS that are a part of the unit
    const lands = new Set([]);
    const OGLS = new Set([]);

    //Provide a list of ids from leases that exist in the system
    const leaseCheckIDs = leaseIDs.map((l) => l.id);

    let editableUnit = Object.assign({}, Unit);

    //Pull lands from unit wells check make sure not null
    if (editableUnit.wells) {
        editableUnit.wells.forEach((w) => {
            w.drilledOn.map((d) => lands.add(d.id));
        });
        //If well has an actual lease relationship get the lease name and lease id to create a link to lease
        editableUnit.wells.map((w) => {
            if (!w.lease) {
                return OGLS.add({id: w.OGL});
            } else {
                return OGLS.add({leaseName: w.lease.leaseName, id: w.lease.id});
            }
        });
        //Capture Land IDs for table - turn set into an array
        editableUnit.landIDs = [...lands].map((m) => ({landId: m}));
        //Capture family IDS to display on form
        editableUnit.familyIds = [...new Set(editableUnit.wells.map((w) => w.familyID))].join(', ');
        //Capture OGLs to for table - turn set into an array
        editableUnit.OGLs = [...OGLS].map((i) => ({id: i.id, leaseName: i.leaseName}));
        editableUnit.OGLs = _.uniqWith(editableUnit.OGLs, _.isEqual);
    }

    return (
        <Formik
            initialValues={{...Unit, sroDOI, mesDOI, mpiDOI}} validationSchema={UnitSchema}
            onSubmit={(values, actions) => {
                return updateUnit(values)
                    .then((data) => {
                        enqueueSnackbar('Unit successfully edited', {variant: 'success'});
                        actions.setSubmitting(false);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err.toString(), {variant: 'error'});
                        actions.setSubmitting(false);
                    });
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography className={classes.title} color='textSecondary' gutterBottom>
                                {editableUnit.name}
                            </Typography>
                        </div>
                        <br/>
                        <hr style={{width: '80%'}}/>
                        <br/>
                        <div style={{display: 'flex'}}>
                            <Field
                                name='name'
                                placeholder='Unit Name'
                                label='Unit Name'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='status'
                                margin='normal'
                                select
                                label='Status'
                                component={TextField}
                                className={classes.TextField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom: 20}}
                            >
                                {
                                    Object.keys(unitStatuses).map((key) => {
                                        return <MenuItem key={key} value={key}>{unitStatuses[key]}</MenuItem>
                                    })
                                }
                            </Field>
                            <div style={{width: 400, zIndex: 99, marginRight: 10}}>
                                <InputLabel>Current Operator</InputLabel>
                                <Field name={'currentOperator'}>
                                    {(props) => {
                                        return (
                                            <CreateableSelect
                                                value={Operators.find((operator) => operator.name === props.field.value)}
                                                options={Operators}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                onChange={(option) => {
                                                    props.form.setFieldValue('currentOperator', option.name);
                                                }}
                                                styles={{
                                                    option: (provided, state) => ({...provided, fontFamily: 'Roboto'}),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontFamily: 'Roboto',
                                                    }),
                                                }}
                                            />
                                        );
                                    }}
                                </Field>
                            </div>
                            <Field
                                name='wells[0].operatedBy.operatorNumber'
                                placeholder='Operator Number'
                                label='Operator Number'
                                component={TextField}
                                className={classes.TextField}
                                disabled
                            />
                            <Field
                                name='unitCreatedBy'
                                placeholder='Unit Created By'
                                label='Unit Created By'
                                component={TextField}
                                className={classes.TextField}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                            <Field
                                name='gloPoolingAgreement'
                                placeholder='GLO Pooling Agreement'
                                label='GLO Pooling'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='rrcNumber'
                                placeholder='RRC Number'
                                label='RRC Number'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='unitGMA'
                                placeholder='Unit GMA'
                                label='Unit GMA'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='familyNMA'
                                placeholder='Family NMA'
                                label='Family NMA'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='leaseRoyaltyRate'
                                placeholder='Royalty Rate'
                                label='Royalty Rate'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='depths'
                                placeholder='Depths Held'
                                label='Depths Held'
                                component={TextField}
                                className={classes.TextField}
                                fullWidth
                                style={{width: 350}}
                            />
                        </div>
                        <div style={{display: 'flex'}}>
                            <Field
                                name='effectiveDate'
                                placeholder='Effective Date'
                                type='date'
                                label='Effective Date'
                                component={TextField}
                                className={classes.TextField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Field
                                name='recordingData'
                                placeholder='Recording Data'
                                label='Recording Data'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='ratifyDate'
                                placeholder='Ratify Date'
                                type='date'
                                label='Ratify Date'
                                component={TextField}
                                className={classes.TextField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Field
                                name='ratifyData'
                                placeholder='Ratify Data'
                                label='Ratify Data'
                                component={TextField}
                                className={classes.TextField}
                            />
                            <Field
                                name='unitType'
                                margin='normal'
                                select
                                label='Type'
                                component={TextField}
                                className={classes.TextField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom: 20}}
                            >
                                <MenuItem value={'DPU'}>DPU</MenuItem>
                                <MenuItem value={'PAA'}>PAA</MenuItem>
                                <MenuItem value={'PSA'}>PSA</MenuItem>
                                <MenuItem value={'Lease Well'}>Lease Well</MenuItem>
                            </Field>
                            <Field
                                name='familyIds'
                                placeholder='Family ID(s)'
                                label='Family ID(s)'
                                component={TextField}
                                className={classes.TextField}
                                fullWidth
                                style={{width: 350}}
                            />
                            <FormControlLabel
                                control={<Field name='original' component={Switch} color='primary'/>}
                                label='Signed Original'
                                labelPlacement='top'
                            />
                        </div>
                        <Paper className={classes.paper}>
                            <Typography className={classes.title} color='textSecondary' gutterBottom>
                                DOI
                            </Typography>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <Field
                                    defaultValue={mesDOI}
                                    disabled
                                    name='mesDOI'
                                    placeholder='MES DOI'
                                    label='MES DOI'
                                    component={TextField}
                                    className={classes.TextField}
                                />
                                <Field
                                    defaultValue={mpiDOI}
                                    disabled
                                    name='mpiDOI'
                                    placeholder='MPI DOI'
                                    label='MPI DOI'
                                    component={TextField}
                                    className={classes.TextField}
                                />
                                <Field
                                    defaultValue={sroDOI}
                                    disabled
                                    name='sroDOI'
                                    placeholder='SRO DOI'
                                    label='SRO DOI'
                                    component={TextField}
                                    className={classes.TextField}
                                />
                            </div>
                        </Paper>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Field
                                name='operatorHistory'
                                placeholder='Notes'
                                label='Operator History Notes'
                                component={TextField}
                                style={{float: 'right'}}
                                multiline={true}
                                rows={4}
                                variant='outlined'
                                className={classes.TextBox}
                                fullWidth
                            />
                            <Field
                                name='propertyDesc'
                                placeholder='Unit Land Description'
                                label='Unit Land Description'
                                component={TextField}
                                multiline={true}
                                rows={4}
                                variant='outlined'
                                className={classes.TextBox}
                                fullWidth
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                            <Field
                                name='unitNotes'
                                placeholder='Notes'
                                label='Notes'
                                component={TextField}
                                multiline={true}
                                rows={4}
                                variant='outlined'
                                className={classes.TextBox}
                                fullWidth
                            />
                        </div>
                        <br/>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            placeContent: 'center center'
                        }}>
                            <div style={{marginRight: 10}}>
                                <FieldArray name='landIDs'>
                                    {() => {
                                        const columns = [
                                            {
                                                title: 'Land ID(s)',
                                                field: 'landId',
                                                render: (rowData) => (
                                                    <Link to={`/Lands/${rowData.landId}`} target='_blank'>
                                                        {rowData.landId}
                                                    </Link>
                                                ),
                                            },
                                        ];

                                        return (
                                            <MaterialTable
                                                columns={columns}
                                                data={editableUnit.landIDs.map(d => ({...d}))}
                                                title={'Lands'}
                                                options={tableOptions}
                                            />
                                        );
                                    }}
                                </FieldArray>
                            </div>
                            <div style={{marginLeft: 10}}>
                                <FieldArray name='OGLs'>
                                    {() => {
                                        const columns = [
                                            {
                                                title: 'Lease Name',
                                                field: 'OGLs',
                                                render: (rowData) => {
                                                    if (leaseCheckIDs.includes(rowData.id)) {
                                                        return (
                                                            <Link to={`/Leases/${rowData.id}`} target='_blank'>
                                                                {rowData.leaseName}
                                                            </Link>
                                                        );
                                                    } else {
                                                        return <div>{rowData.id}</div>;
                                                    }
                                                },
                                            },
                                        ];

                                        return (
                                            <MaterialTable
                                                columns={columns}
                                                data={props.values.OGLs}
                                                title={'OGL(s)'}
                                                options={tableOptions}
                                            />
                                        );
                                    }}
                                </FieldArray>
                            </div>
                            <div style={{marginLeft: 10, overflowY: "auto"}}>
                                <FieldArray name='wells'>
                                    {({push, insert, remove}) => {
                                        const columns = [
                                            {
                                                title: 'Wells',
                                                field: 'name',
                                                editComponent: ({onChange}) => (
                                                    <Select
                                                        options={wells}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={onChange}
                                                        styles={{
                                                            menu: (provided, state) => ({...provided, width: 150, zIndex: 9999}),
                                                            menuList: (provided, state) => ({...provided, width: 150}),
                                                            menuPortal: (provided, state) => ({...provided, zIndex: 9999}),
                                                        }}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'fixed'}
                                                    />
                                                ),
                                                render: (rowData) => (
                                                    <Link to={`/Wells/${rowData.id}`} target='_blank'>
                                                        {rowData.name}
                                                    </Link>
                                                ),
                                            },
                                        ];

                                        return (
                                            <MaterialTable
                                                columns={columns}
                                                data={props.values.wells.map(w => ({...w}))}
                                                title={'Unit Wells'}
                                                options={tableOptions}
                                                editable={{
                                                    onRowAdd: (newData) => {
                                                        return addWell({
                                                            variables: {
                                                                from: {id: newData.name.id},
                                                                to: {id: props.values.id},
                                                            },
                                                        })
                                                            .then(({data: {AddUnitWells}}) => push(AddUnitWells.from))
                                                            .catch((error) => console.log(error));
                                                    },
                                                    onRowDelete: (oldData) => {
                                                        return removeWell({
                                                            variables: {
                                                                from: {id: oldData.id},
                                                                to: {id: props.values.id},
                                                            },
                                                        })
                                                            .then(() => remove(oldData.tableData.id))
                                                            .catch((error) => console.log(error));
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                </FieldArray>
                            </div>
                        </div>
                        <Field
                            name='mapUrl'
                            placeholder='Map Url'
                            label='Map Url'
                            component={TextField}
                            className={classes.TextField}
                            fullWidth
                        />
                        <div style={{display: hasPermissions ? 'block' : 'none'}}>
                            <Button
                                variant='contained'
                                color='secondary'
                                className={classes.deleteButton}
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            >
                                Delete Unit
                            </Button>

                            <DeleteUnitModal
                                open={modalOpen}
                                handleClose={() => setModalOpen(false)}
                                handleAgree={() => {
                                    deleteUnit({
                                        variables: {
                                            id: props.values.id,
                                        },
                                        update: (store, {data: {DeleteUnit}}) => {
                                            store.data.delete(DeleteUnit.id);
                                        },
                                    })
                                        .then(({data}) => {
                                            enqueueSnackbar('Successfully deleted Unit', {variant: 'success'});
                                            history.push('/Units');
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            enqueueSnackbar(err.toString, {variant: 'error'});
                                        });
                                }}
                            />
                            <Button variant='contained' color='primary' type='submit' style={{float: 'right'}}>
                                Edit Unit
                            </Button>
                        </div>
                        <div style={{marginTop: 30}}>
                            <Iframe
                                url={props.values.mapUrl && props.values.mapUrl !== 'Default' ? props.values.mapUrl : ''}
                                width='100%'
                                height='100%'
                                style={{display: 'none'}}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UnitDetailPage;

import gql from 'graphql-tag';

export const emptyMineralOwner = {
  mineralFraction: '0/1',
  netMineralAcres: 0,
  mineralPercentage: 0,
};

export const emptySurfaceOwner = {
  surfaceFraction: '0/1',
  netSurfaceAcres: 0,
  surfacePercentage: 0,
};

export const DefaultTract = {
  name: 'None',
  abstract: 'None',
  blk: 'None',
  bonusDelayAcres: 0,
  caliche: false,
  calicheAcres: 0,
  county: 'None',
  description: 'None',
  executiveRights: false,
  familyNMA: '0',
  familyNSA: '0',
  familyID: 'None',
  grossMineralAcres: 0,
  grossSurfaceAcres: 0,
  mineralOwnership: false,
  notes: 'None',
  orriPercentage: '0',
  npriPercentage: '0',
  state: 'None',
  surfaceOwnership: false,
  township: 'None',
  tract: 'None',
  bonusDelay: false,
  mineralClassifiedAcres: 0,
  mineralClassified: false,
  mineralFraction: '0',
  mineralOwnershipPercentage: '0',
  otherMineralAcres: 0,
  originalPapers: 'None',
  otherMinerals: false,
  overRidingRoyaltyInterest: false,
  npriInterest: false,
  surfaceFraction: '0',
  surfaceOwnershipPercentage: '0',
  waterAcres: '',
  purchaser: 'None',
  recording: 'None',
  docType: 'None',
  purchaseDate: '0001-01-01',
  survey: 'None',
  geometry: '',
  bfNumber: 'None',
};

const LAND_FRAGMENT = gql`
    {
        id
        name
        abstract
        acreage
        blk
        bonusDelayAcres
        caliche
        calicheAcres
        county
        description
        drivingDirections
        legalDescription
        executiveRights
        familyNMA
        familyNSA
        familyID
        grossMineralAcres
        grossSurfaceAcres
        mineralOwnership
        mineralOwnershipPercentage
        surfaceOwnershipPercentage
        notes
        orriAcres
        orriPercentage
        npriPercentage
        state
        section
        surfaceOwnership
        township
        tract
        bonusDelay
        mineralClassifiedAcres
        mineralClassified
        otherMineralAcres
        originalPapers
        otherMinerals
        overRidingRoyaltyInterest
        npriInterest
        waterAcres
        wellCount
        geometry
        purchaser
        recording
        docType
        purchaseDate
        range
        color
        countyMap
        detailMap
        waterOwnership
        mineralFraction
        surfaceFraction
        ownershipNotes
        survey
        patentInformation
        dateOfPatent
        bfNumber
        volumePage
        otherLeases {
            id
            projectName
        }
        titleRun {
            id
            volPage
            book
            dateFiled
            effectiveDate
            docType
            comments
            grantor
            grantee
            nsaTotal
            nmaTotal
            landDescription
            fileName
        }
        drilledOn {
            name
            id
            status
            operatedBy {
                name
            }
            drilledOn {
                id
            }
        }
        rowCount
        swdCount
        rowsOn {
            type
            projectName
            lessee
            dateOfExpiration
            status
            id
        }
        mineralsOwned {
            Owner {
                name
                id
            }
            id
            mineralPercentage
            netMineralAcres
            mineralFraction
        }
        surfaceOwned {
            Owner {
                id
                name
            }
            id
            surfacePercentage
            netSurfaceAcres
            surfaceFraction
        }
        npri {
            Owner {
                id
                name
            }
            id
            mineralFraction
            mineralPercentage
        }
        interest {
            id
            netMineralAcres
            netSurfaceAcres
            Lease {
                id
                leaseName
                currentLessee
                status
            }
        }
    }
`;

export const TRACTS_QUERY = gql`
    query{
        Tract(orderBy: id_asc)
        ${LAND_FRAGMENT}
    }
`;

export const TRACTS_TABLE_QUERY = gql`
  query {
    Tract(orderBy: id_asc) {
      id
      familyNMA
      familyNSA
      state
      county
      color
      grossMineralAcres
      grossSurfaceAcres
      mineralFraction
      mineralsOwned {
          Owner {
              name
              id
          }
          id
          mineralPercentage
          netMineralAcres
          mineralFraction
      }
      surfaceOwned {
          Owner {
              id
              name
          }
          id
          surfacePercentage
          netSurfaceAcres
          surfaceFraction
      }
    }
  }
`;

export const GET_TRACT = gql`
    query GetTract($id: ID) {
        Tract(id: $id)
        ${LAND_FRAGMENT}
    }
`;

export const GET_TRACT_NAMES = gql`
  query {
    Tract {
      id
      name
      state
      county
    }
  }
`;

export const ADD_TRACT = gql`
  mutation CreateTract(
    $id: ID!
    $name: String!
    $abstract: String
    $acreage: String
    $blk: String
    $bonusDelayAcres: Float
    $caliche: Boolean
    $calicheAcres: Float
    $county: String!
    $description: String
    $drivingDirections: String
    $legalDescription: String
    $executiveRights: Boolean
    $familyNMA: String
    $familyNSA: String
    $familyID: String
    $grossMineralAcres: Float
    $grossSurfaceAcres: Float
    $mineralOwnership: Boolean!
    $notes: String
    $orriPercentage: String
    $orriAcres: Float
    $npriPercentage: String
    $state: String!
    $section: String
    $surfaceOwnership: Boolean!
    $township: String
    $tract: String
    $bonusDelay: Boolean
    $mineralClassifiedAcres: Float
    $mineralClassified: Boolean
    $mineralFraction: String
    $mineralOwnershipPercentage: String
    $otherMineralAcres: Float
    $originalPapers: String
    $otherMinerals: Boolean
    $overRidingRoyaltyInterest: Boolean
    $npriInterest: Boolean
    $surfaceFraction: String
    $surfaceOwnershipPercentage: String
    $waterAcres: String
    $geometry: String
    $purchaser: String
    $recording: String
    $docType: String
    $purchaseDate: String
    $range: String
    $color: String
    $survey: String
    $dateOfPatent: String
    $patentInformation: String
    $bfNumber: String
    $volumePage: String
  ) {
    CreateTract(
      id: $id
      name: $name
      abstract: $abstract
      acreage: $acreage
      blk: $blk
      bonusDelayAcres: $bonusDelayAcres
      caliche: $caliche
      calicheAcres: $calicheAcres
      county: $county
      description: $description
      drivingDirections: $drivingDirections
      legalDescription: $legalDescription
      executiveRights: $executiveRights
      familyNMA: $familyNMA
      familyNSA: $familyNSA
      familyID: $familyID
      grossMineralAcres: $grossMineralAcres
      grossSurfaceAcres: $grossSurfaceAcres
      mineralOwnership: $mineralOwnership
      notes: $notes
      orriPercentage: $orriPercentage
      orriAcres: $orriAcres
      npriPercentage: $npriPercentage
      state: $state
      section: $section
      surfaceOwnership: $surfaceOwnership
      township: $township
      tract: $tract
      bonusDelay: $bonusDelay
      mineralClassifiedAcres: $mineralClassifiedAcres
      mineralClassified: $mineralClassified
      mineralFraction: $mineralFraction
      mineralOwnershipPercentage: $mineralOwnershipPercentage
      otherMineralAcres: $otherMineralAcres
      originalPapers: $originalPapers
      otherMinerals: $otherMinerals
      overRidingRoyaltyInterest: $overRidingRoyaltyInterest
      npriInterest: $npriInterest
      surfaceFraction: $surfaceFraction
      surfaceOwnershipPercentage: $surfaceOwnershipPercentage
      waterAcres: $waterAcres
      geometry: $geometry
      purchaser: $purchaser
      recording: $recording
      docType: $docType
      purchaseDate: $purchaseDate
      range: $range
      color: $color
      survey: $survey
      dateOfPatent: $dateOfPatent
      patentInformation: $patentInformation
      bfNumber: $bfNumber
      volumePage: $volumePage
    ) {
      name
    }
  }
`;

export const EDIT_TRACT = gql`
    mutation UpdateTract(
        $id: ID!
        $name: String!
        $abstract: String
        $acreage: String
        $blk: String
        $bonusDelayAcres: Float
        $caliche: Boolean
        $calicheAcres: Float
        $county: String
        $description: String
        $drivingDirections: String
        $legalDescription: String
        $executiveRights: Boolean
        $familyNMA: String
        $familyNSA: String
        $familyID: String
        $grossMineralAcres: Float
        $grossSurfaceAcres: Float
        $mineralOwnership: Boolean
        $notes: String
        $orriPercentage: String
        $orriAcres: Float
        $npriPercentage: String
        $state: String
        $section: String
        $surfaceOwnership: Boolean
        $township: String
        $tract: String
        $bonusDelay: Boolean
        $mineralClassifiedAcres: Float
        $mineralClassified: Boolean
        $mineralFraction: String
        $mineralOwnershipPercentage: String
        $otherMineralAcres: Float
        $originalPapers: String
        $otherMinerals: Boolean
        $overRidingRoyaltyInterest: Boolean
        $npriInterest: Boolean
        $surfaceFraction: String
        $surfaceOwnershipPercentage: String
        $waterAcres: String
        $geometry: String
        $purchaser: String
        $recording: String
        $docType: String
        $purchaseDate: String
        $range: String
        $color: String
        $countyMap: String
        $detailMap: String
        $waterOwnership: Boolean
        $ownershipNotes: String
        $survey: String
        $dateOfPatent: String
        $patentInformation: String
        $bfNumber: String
        $volumePage: String
    ) {
        UpdateTract(
            id: $id
            name: $name
            abstract: $abstract
            acreage: $acreage
            blk: $blk
            bonusDelayAcres: $bonusDelayAcres
            caliche: $caliche
            calicheAcres: $calicheAcres
            county: $county
            description: $description
            drivingDirections: $drivingDirections
            legalDescription: $legalDescription
            executiveRights: $executiveRights
            familyNMA: $familyNMA
            familyNSA: $familyNSA
            familyID: $familyID
            grossMineralAcres: $grossMineralAcres
            grossSurfaceAcres: $grossSurfaceAcres
            mineralOwnership: $mineralOwnership
            notes: $notes
            orriPercentage: $orriPercentage
            orriAcres: $orriAcres
            npriPercentage: $npriPercentage
            state: $state
            section: $section
            surfaceOwnership: $surfaceOwnership
            township: $township
            tract: $tract
            bonusDelay: $bonusDelay
            mineralClassifiedAcres: $mineralClassifiedAcres
            mineralClassified: $mineralClassified
            mineralFraction: $mineralFraction
            mineralOwnershipPercentage: $mineralOwnershipPercentage
            otherMineralAcres: $otherMineralAcres
            originalPapers: $originalPapers
            otherMinerals: $otherMinerals
            overRidingRoyaltyInterest: $overRidingRoyaltyInterest
            npriInterest: $npriInterest
            surfaceFraction: $surfaceFraction
            surfaceOwnershipPercentage: $surfaceOwnershipPercentage
            waterAcres: $waterAcres
            geometry: $geometry
            purchaser: $purchaser
            recording: $recording
            docType: $docType
            purchaseDate: $purchaseDate
            range: $range
            color: $color
            countyMap: $countyMap
            detailMap: $detailMap
            waterOwnership: $waterOwnership
            ownershipNotes: $ownershipNotes
            survey: $survey
            dateOfPatent: $dateOfPatent
            patentInformation: $patentInformation
            bfNumber: $bfNumber
            volumePage: $volumePage
        )
        ${LAND_FRAGMENT}
    }
`;

export const REMOVE_TRACT_MINERAL_OWNERSHIP = gql`
  mutation RemoveOwnerMinerals($from: _OwnerInput!, $to: _TractInput!) {
    RemoveOwnerMinerals(from: $from, to: $to) {
      from {
        name
      }
      to {
        id
      }
    }
  }
`;

/**
 * OwnerInput:
 * - name: String!
 *
 * TractInput:
 * - id: ID!
 */
export const REMOVE_TRACT_SURFACE_OWNERSHIP = gql`
  mutation RemoveOwnerSurface($from: _OwnerInput!, $to: _TractInput!) {
    RemoveOwnerSurface(from: $from, to: $to) {
      from {
        name
      }
      to {
        id
      }
    }
  }
`;

/**
 * MineralOwnershipInput:
 * - mineralPercentage: Float
 * - mineralFraction: String
 * - netMineralAcres: Float
 */
export const ADD_TRACT_MINERAL_OWNERSHIP = gql`
  mutation AddOwnerMinerals($from: _OwnerInput!, $to: _TractInput!, $data: _MineralOwnershipInput!) {
    AddOwnerMinerals(from: $from, to: $to, data: $data) {
      from {
        name
        id
      }
      to {
        id
      }
      id
      mineralPercentage
      mineralFraction
      netMineralAcres
    }
  }
`;

/**
 * SurfaceOwnershipInput:
 * - surfacePercentage: Float
 * - surfaceFraction: String
 * - netSurfaceAcres: Float
 */
export const ADD_TRACT_SURFACE_OWNERSHIP = gql`
  mutation AddOwnerSurface($from: _OwnerInput!, $to: _TractInput!, $data: _SurfaceOwnershipInput!) {
    AddOwnerSurface(from: $from, to: $to, data: $data) {
      from {
        name
        id
      }
      to {
        id
      }
      surfacePercentage
      surfaceFraction
      netSurfaceAcres
    }
  }
`;

export const ADD_TRACT_NPRI_OWNERSHIP = gql`
  mutation AddTractNpri($from: _OwnerInput!, $to: _TractInput!, $data: _NPRIInput!) {
    AddTractNpri(from: $from, to: $to, data: $data) {
      from {
        name
        id
      }
      to {
        id
      }
      id
      mineralPercentage
      mineralFraction
    }
  }
`;

export const REMOVE_TRACT_NPRI_OWNERSHIP = gql`
  mutation RemoveTractNpri($from: _OwnerInput!, $to: _TractInput!) {
    RemoveTractNpri(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const DELETE_TRACT = gql`
  mutation DeleteTract($id: ID!) {
    DeleteTract(id: $id) {
      id
      name
    }
  }
`;

export const GET_ALL_TRACT_IDS = gql`
  query {
    Tract {
      id
    }
  }
`;

export const MAP_QUERY = gql`
  query {
    Tract {
      id
      geometry
      drilledOn {
        name
        latitude
        latitudeBottom
        longitude
        longitudeBottom
        upperPerf
        lowerPerf
        perfLength
        trueVerticalDepth
      }
    }
  }
`;

export const CREATE_LAND_TITLE = gql`
    mutation createTitleDocument(
        $tractID: ID!
        $volPage: String
        $book: String
        $effectiveDate: String
        $dateFiled: String
        $docType: String
        $comments: String
        $grantor: String
        $grantee: String
        $nsaTotal: String
        $nmaTotal: String
        $landDescription: String
    ) {
        createTitleDocument(
            tractID: $tractID
            volPage: $volPage
            book: $book
            effectiveDate: $effectiveDate
            dateFiled: $dateFiled
            docType: $docType
            comments: $comments
            grantor: $grantor
            grantee: $grantee
            nsaTotal: $nsaTotal
            nmaTotal: $nmaTotal
            landDescription: $landDescription
        )
        ${LAND_FRAGMENT}
    }
`;

export const DELETE_TITLE_DOCUMENT = gql`
  mutation DeleteLandTitle($id: ID!) {
    DeleteLandTitle(id: $id) {
      id
    }
  }
`;

export const EDIT_TITLE_DOCUMENT = gql`
    mutation UpdateLandTitle(
        $id: ID!
        $volPage: String
        $book: String
        $effectiveDate: String
        $dateFiled: String
        $docType: String
        $comments: String
        $grantor: String
        $grantee: String
        $nsaTotal: String
        $nmaTotal: String
        $landDescription: String
        $fileName: String
    ) {
        UpdateLandTitle(
            id: $id
            volPage: $volPage
            book: $book
            effectiveDate: $effectiveDate
            dateFiled: $dateFiled
            docType: $docType
            comments: $comments
            grantor: $grantor
            grantee: $grantee
            nsaTotal: $nsaTotal
            nmaTotal: $nmaTotal
            landDescription: $landDescription
            fileName: $fileName
        ) {
            id
            volPage
            book
            dateFiled
            effectiveDate
            docType
            comments
            grantor
            grantee
            nsaTotal
            nmaTotal
            landDescription
            fileName
        }
    }
`;

export const EDIT_MINERAL_OWNERSHIP = gql`
  mutation updateMineralOwnership(
    $relationshipID: ID!
    $mineralFraction: String
    $mineralPercentage: Float
    $netMineralAcres: Float
  ) {
    updateMineralOwnership(
      relationshipID: $relationshipID
      mineralFraction: $mineralFraction
      mineralPercentage: $mineralPercentage
      netMineralAcres: $netMineralAcres
    ) {
      id
      netMineralAcres
      mineralFraction
      mineralPercentage
    }
  }
`;

export const EDIT_SURFACE_OWNERSHIP = gql`
  mutation updateSurfaceOwnership(
    $relationshipID: ID!
    $surfaceFraction: String
    $surfacePercentage: Float
    $netSurfaceAcres: Float
  ) {
    updateSurfaceOwnership(
      relationshipID: $relationshipID
      surfaceFraction: $surfaceFraction
      surfacePercentage: $surfacePercentage
      netSurfaceAcres: $netSurfaceAcres
    ) {
      id
      netSurfaceAcres
      surfaceFraction
      surfacePercentage
    }
  }
`;

export const EDIT_NPRI = gql`
  mutation updateNPRI($relationshipID: ID!, $mineralFraction: String, $mineralPercentage: String) {
    updateNPRI(
      relationshipID: $relationshipID
      mineralFraction: $mineralFraction
      mineralPercentage: $mineralPercentage
    ) {
      id
      mineralFraction
      mineralPercentage
    }
  }
`;

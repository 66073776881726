import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import { Edit, Save, Add, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const tableStyle = {
  paddingTop: '1px',
  paddingBottom: '1px'
};

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 16,
    padding: 8
  },
  tableHeader: {
    fontWeight: 'bold'
  },
  pillSize: {
    height: 22,
    fontSize: '0.75rem',
    '& .MuiChip-label': {
      paddingLeft: 4,
      paddingRight: 4
    }
  },
  newStateRow: {
    backgroundColor: '#eee'
  },
  addCountyField: {
    paddingTop: 4
  }
}));

/**
 * A simple modal for confirming a county deletion
 */
const DeleteCountyModal = ({ open, countyName, onClose, onConfirm }) => (
  <Dialog open={open} onClose={() => onClose()}>
    <DialogTitle>Are you sure you want to delete this county?</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Warning: This cannot be reversed.
      </Typography>
      <Typography variant="body1">{countyName}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()} color="secondary">
        Close
      </Button>
      <Button
        color="primary"
        onClick={() => {
          onConfirm();
          onClose();
        }}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

/**
 * AdminSettings
 *
 * props.data.states => array of states from the server, each shaped like:
 *   { id, stateName, counties: [ { id, name } ] }
 * props.mutations => { AddState, UpdateState, DeleteState }
 */
const AdminSettings = ({ data: { states = [] }, mutations: { AddState, UpdateState, DeleteState, DeleteCounty } }) => {
  const classes = useStyles();

  /**
   * localStates:
   *   [ { id, name, counties: [ { id, name } ] } ]
   */
  const [localStates, setLocalStates] = useState([]);

  // For editing a state's name
  const [editStateId, setEditStateId] = useState(null);
  const [editStateName, setEditStateName] = useState('');

  // For adding new counties to an existing state
  const [countyInputs, setCountyInputs] = useState({});

  // For deleting a county via a modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [countyToDelete, setCountyToDelete] = useState(null);
  const [stateIdForDelete, setStateIdForDelete] = useState(null);

  // For creating a brand new state
  const [newStateName, setNewStateName] = useState('');
  const [newStateCountyInput, setNewStateCountyInput] = useState('');
  const [newStateCounties, setNewStateCounties] = useState([]);

  // On mount or whenever `states` changes, store them in local state
  useEffect(() => {
    if (Array.isArray(states)) {
      const mapped = states.map(s => ({
        id: s.id,
        name: s.stateName,
        counties: s.counties ? s.counties.map(c => ({ id: c.id, name: c.name })) : []
      }));
      setLocalStates(mapped);
    }
  }, [states]);

  /**
   * ============ 1) ADDING A BRAND-NEW STATE WITH COUNTIES ============
   */
  const handleAddCountyToNewState = () => {
    if (!newStateCountyInput.trim()) return;
    setNewStateCounties(prev => [
      ...prev,
      { id: Date.now(), name: newStateCountyInput.trim() }
    ]);
    setNewStateCountyInput('');
  };

  const handleCreateNewState = () => {
    if (!newStateName.trim()) return;

    // Build an array of county names
    const countyNames = newStateCounties.map(c => c.name);

    AddState({
      variables: { stateName: newStateName.trim(), counties: countyNames }
    })
      .then(res => {
        const newId = res.data.addState.id;
        const actualName = res.data.addState.stateName;
        const actualCounties = res.data.addState.counties || [];

        // Insert it into localStates
        setLocalStates(prev => [
          ...prev,
          {
            id: newId,
            name: actualName,
            counties: actualCounties
          }
        ]);

        // Reset
        setNewStateName('');
        setNewStateCounties([]);
        setNewStateCountyInput('');
      })
      .catch(err => {
        console.error('Error creating new state:', err.message);
      });
  };

  /**
   * ============ 2) EDITING A STATE'S NAME ============
   */
  const handleStartEditState = (stateId, currentName) => {
    setEditStateId(stateId);
    setEditStateName(currentName);
  };

  const handleSaveEditState = (stateId) => {
    // Find the existing counties so we don't wipe them out
    const theState = localStates.find(s => s.id === stateId);
    if (!theState) return;

    const newName = editStateName.trim();
    const countyNames = theState.counties.map(c => c.name);

    // We'll call UpdateState with same counties, just new name
    UpdateState({
      variables: {
        id: stateId,
        stateName: newName,
        counties: countyNames
      }
    })
      .then(() => {
        // Reflect changes locally
        setLocalStates(prev => prev.map(s => {
          if (s.id === stateId) {
            return { ...s, name: newName };
          }
          return s;
        }));
      })
      .catch(err => {
        console.error('Error updating state name:', err.message);
      });

    setEditStateId(null);
    setEditStateName('');
  };

  /**
   * ============ 3) ADDING A COUNTY TO AN EXISTING STATE ============
   */
  const handleCountyInputChange = (stateId, value) => {
    setCountyInputs(prev => ({ ...prev, [stateId]: value }));
  };

  const handleAddCountyToExistingState = (stateId) => {
    const inputVal = countyInputs[stateId]?.trim();
    if (!inputVal) return;

    // 1) Update local first
    setLocalStates(prev => prev.map(s => {
      if (s.id === stateId) {
        return {
          ...s,
          counties: [...s.counties, { id: Date.now(), name: inputVal }]
        };
      }
      return s;
    }));

    // 2) Update DB by re-calling UpdateState with the new county list
    const theState = localStates.find(s => s.id === stateId);
    if (!theState) return;

    const newCountyNames = [...theState.counties.map(c => c.name), inputVal];

    UpdateState({
      variables: {
        id: stateId,
        stateName: theState.name, // keep same name
        counties: newCountyNames
      }
    }).catch(err => {
      console.error('Error adding county:', err.message);
    });

    // Clear input
    setCountyInputs(prev => ({ ...prev, [stateId]: '' }));
  };

  /**
   * ============ 4) DELETING A COUNTY FROM AN EXISTING STATE ============
   */
  const handleRequestDeleteCounty = (stateId, countyName) => {
    setDeleteModalOpen(true);
    console.log(countyName, "WHAT IS THIS COUNTY NAME THING ?")
    setCountyToDelete(countyName.id);
    setStateIdForDelete(stateId);
  };

  const handleConfirmDeleteCounty = () => {
    if (!countyToDelete) return;
    DeleteCounty({ variables: { id: countyToDelete } })

    // 1) Update local
    setLocalStates(prev => prev.map(s => {
      if (s.id === stateIdForDelete) {
        return {
          ...s,
          counties: s.counties.filter(c => c.name !== countyToDelete)
        };
      }
      return s;
    }));

    // 2) Update DB
    const theState = localStates.find(s => s.id === stateIdForDelete);
    if (theState) {
      const newCountyNames = theState.counties
        .filter(c => c.name !== countyToDelete)
        .map(c => c.name);

      UpdateState({
        variables: {
          id: stateIdForDelete,
          stateName: theState.name,
          counties: newCountyNames
        }
      }).catch(err => {
        console.error('Error removing county:', err.message);
      });
    }
  };

  /**
   * ============ 5) DELETING AN ENTIRE STATE ============
   */
  const handleDeleteState = (state) => {
    DeleteState({ variables: { id: state.id } })
      .then(res => {
        if (res.data.deleteStateByIdentity) {
          setLocalStates(prev => prev.filter(s => s.id !== state.id));
        } else {
          console.log('Could not delete state, DB returned false');
        }
      })
      .catch(err => {
        console.error('Error deleting state:', err.message);
      });
  };

  return (
    <div>
      <Typography variant="h5" className={classes.tableContainer}>
        States and Counties
      </Typography>

      <Paper style={tableStyle}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>State</TableCell>
              <TableCell className={classes.tableHeader}>Counties</TableCell>
              <TableCell className={classes.tableHeader}>Add County</TableCell>
              <TableCell className={classes.tableHeader}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {localStates.map(row => {
              const isEditing = editStateId === row.id;

              return (
                <TableRow key={row.id}>
                  {/* STATE NAME or EDIT FIELD */}
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        value={editStateName}
                        onChange={e => setEditStateName(e.target.value)}
                      />
                    ) : (
                      row.name
                    )}
                  </TableCell>

                  {/* COUNTIES COLUMN */}
                  <TableCell>
                    {row.counties.map(county => (
                      <Chip
                        key={county.id}
                        label={county.name}
                        onDelete={() => handleRequestDeleteCounty(row.id, county)}
                        deleteIcon={<Delete />}
                        color="default"
                        className={classes.pillSize}
                      />
                    ))}
                  </TableCell>

                  {/* ADD A NEW COUNTY TO THIS STATE */}
                  <TableCell>
                    <TextField
                      variant="outlined"
                      placeholder="County Name"
                      value={countyInputs[row.id] || ''}
                      onChange={e => handleCountyInputChange(row.id, e.target.value)}
                      className={classes.addCountyField}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => handleAddCountyToExistingState(row.id)}
                    >
                      <Add />
                    </IconButton>
                  </TableCell>

                  {/* ACTIONS: SAVE NAME, DELETE STATE */}
                  <TableCell>
                    {isEditing ? (
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveEditState(row.id)}
                      >
                        <Save />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => handleStartEditState(row.id, row.name)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteState(row)}
                      style={{ marginLeft: 8 }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}

            {/* NEW STATE ROW AT THE BOTTOM */}
            <TableRow className={classes.newStateRow}>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="New State Name"
                  className={classes.addCountyField}
                  value={newStateName}
                  onChange={e => setNewStateName(e.target.value)}
                />
              </TableCell>

              <TableCell>
                {newStateCounties.map(county => (
                  <Chip key={county.id} label={county.name} color="default" />
                ))}
              </TableCell>

              <TableCell>
                <TextField
                  variant="outlined"
                  label="New County Name"
                  className={classes.addCountyField}
                  value={newStateCountyInput}
                  onChange={e => setNewStateCountyInput(e.target.value)}
                />
                <IconButton color="primary" onClick={handleAddCountyToNewState}>
                  <Add />
                </IconButton>
              </TableCell>

              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewState}
                >
                  Create
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <DeleteCountyModal
        open={deleteModalOpen}
        countyName={countyToDelete}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDeleteCounty}
      />
    </div>
  );
};

export default AdminSettings;

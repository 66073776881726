import React, { useState } from 'react';
import {
  CREATE_LAND_TITLE,
  DELETE_TITLE_DOCUMENT,
  EDIT_MINERAL_OWNERSHIP,
  EDIT_SURFACE_OWNERSHIP,
  EDIT_TITLE_DOCUMENT,
  GET_TRACT,
  TRACTS_QUERY,
} from '../../components/tracts/TractQueries';
import EditTractForm from './editTractForm/EditTractForm';
import AddIcon from '@material-ui/icons/Add';
import LoadingView from '../../components/LoadingView';
import {
  Typography,
  Tab,
  Tabs,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { DELETE_TRACT, EDIT_TRACT } from '../../components/tracts/TractQueries';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import LandWellsTable from './LandWellsTable';
import LandROWTable from './LandROWTable';
import LandsLegal from './LandsLegal';
import ChainOfTitleTable from './ChainOfTitleTable';
import math from 'mathjs';
import OGLTable from './OGLTable';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import OtherLeasesTable from './editTractForm/OtherLeasesTable';
import DeleteTractModal from './DeleteTractModal';
import Uploader from '../../util/Uploader';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const useStyles = makeStyles((theme) => ({
  isAuthorized: {
    display: hasPermissions ? 'none' : 'block',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
    align: 'center',
  },
}));

const TitleFragment = gql`
    fragment TitleFragment on Tract {
        id
        titleRun {
            id
            volPage
            book
            dateFiled
            effectiveDate
            docType
            comments
            grantor
            grantee
            nsaTotal
            nmaTotal
            fileName
        }
    }
`;

const propertyValues = (fraction) => (gross) => {
  const decimal = math.number(math.fraction(fraction));
  return {
    net: math.multiply(decimal, gross),
    decimal: math.multiply(decimal, 100),
  };
};

const submitValues = (values) => ({
  ...values,
  grossMineralAcres: parseFloat(values.grossMineralAcres),
  grossSurfaceAcres: parseFloat(values.grossSurfaceAcres),
  mineralOwnershipPercentage: propertyValues(values.mineralFraction)(
    values.grossMineralAcres
  ).decimal.toFixed(8),
  surfaceOwnershipPercentage: propertyValues(values.surfaceFraction)(
    values.grossSurfaceAcres
  ).decimal.toFixed(8),
  familyNMA: propertyValues(values.mineralFraction)(
    values.grossMineralAcres
  ).net.toFixed(8),
  familyNSA: propertyValues(values.surfaceFraction)(
    values.grossSurfaceAcres
  ).net.toFixed(8),
  mineralOwnership: values.grossMineralAcres !== 0,
  surfaceOwnership: values.grossSurfaceAcres !== 0,
});

function TabContainer(props) {
  return (
    <Typography component='div' style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const GetTractFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }
  return queryResult[0];
};

function EditTract({ match, history }) {
  const [, setLoading] = React.useState(false);
  const onSubmitMap = (values, actions, mapType) => {
    console.log(mapType, "SHOULD BE THE MAP TYPE")
    console.log(values, "SHOULD BE THE VALUES")
    console.log(actions, "ACTIONS")
    const mapUpdate = { ...editableTract };
    mapUpdate[mapType] = values[mapType];
    actions.setSubmitting(true);

    UpdateTract({ variables: mapUpdate })
      .then(() => {
        enqueueSnackbar('Successfully edited Land!', { variant: 'success' });
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.toString(), { variant: 'error' });
        actions.setSubmitting(false);
      });
  };

  const classes = useStyles();
  const { data, error, loading } = useQuery(GET_TRACT, {
    variables: {
      id: match.params.tractID,
    },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [UpdateTract] = useMutation(EDIT_TRACT);
  const [DeleteTract] = useMutation(DELETE_TRACT, {
    update: (store, { data: { DeleteTract } }) => {
      try {
        const LandQuery = store.readQuery({ query: TRACTS_QUERY });

        const newData = LandQuery.Tract.filter(
          (tract) => tract.id !== DeleteTract.id
        );

        store.writeQuery({
          query: TRACTS_QUERY,
          data: { Tract: newData },
        });
      } catch (e) {
        console.log('TRACTS_QUERY has not been run yet!');
      }
    },
  });

  const [addTitle] = useMutation(CREATE_LAND_TITLE);
  const [removeTitle] = useMutation(DELETE_TITLE_DOCUMENT);
  const [updateTitle] = useMutation(EDIT_TITLE_DOCUMENT);
  const [editMinerals] = useMutation(EDIT_MINERAL_OWNERSHIP);
  const [editSurface] = useMutation(EDIT_SURFACE_OWNERSHIP);

  // For the DeleteTractModal
  const [modalOpen, setModalOpen] = useState(false);

  const handleDelete = () => {
    DeleteTract({
      variables: { id: editableTract.id },
    })
      .then(() => {
        enqueueSnackbar('Successfully deleted Tract', { variant: 'success' });
        history.push('/Lands');
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.toString(), { variant: 'error' });
      });
  };

  const updateSurfaceOwnership = (values) =>
    editSurface({ variables: values });

  const updateMineralOwnership = (values) =>
    editMinerals({ variables: values });

  const editTitleDoc = (values) => updateTitle({ variables: values });

  const createTitleDoc = (values) => addTitle({ variables: values });

  const deleteTitleDoc = (values) =>
    removeTitle({
      variables: values,
      update: (store, { data: { DeleteClause } }) => {
        try {
          const oldTitle = store.readFragment({
            id: match.params.tractID,
            fragment: TitleFragment,
          });

          const updatedTitle = oldTitle.titleRun.filter(
            (title) => values.id !== title.id
          );

          store.writeFragment({
            id: match.params.tractID,
            fragment: TitleFragment,
            data: {
              ...oldTitle,
              titleRun: updatedTitle,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });

  const [value, setValue] = useState(0);

  if (error) {
    console.error(error);
    return <h1>{error.toString()}</h1>;
  }
  if (loading) {
    return <LoadingView />;
  }

  const Tract = GetTractFromQuery(data.Tract) || [];
  const editableTract = { ...Tract }; // or Object.assign({}, Tract)

  return (
    <>
      <AppBar
        position="sticky"
        style={{
          top: 64,
          zIndex: 10
        }}
      >
        <Toolbar
        >
          <Tabs value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e, newVal) => setValue(newVal)}>
            <Tab label='Main' style={{ minWidth: 1, padding: '0 0px' }} />
            <Tab label='County Map' style={{ minWidth: 1, padding: '0 0px' }}/>
            <Tab label='Detail Map' style={{ minWidth: 1, padding: '0 0px' }} />
            <Tab label='Chain of Title' style={{ minWidth: 1, padding: '0 0px' }}/>
            <Tab label='Wells' style={{ minWidth: 1, padding: '0 0px' }}/>
            <Tab label='ROW' style={{ minWidth: 1, padding: '0 0px' }}/>
            <Tab label='OGL(S)' style={{ minWidth: 1, padding: '0 0px' }}/>
            <Tab label='Other Leases' style={{ minWidth: 1, padding: '0 0px' }}/>
          </Tabs>
          {/* BUTTONS on the right */}
          {value === 0 && hasPermissions && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                form="editTractForm"
                style={{
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                  padding: '4px 10px',
                }}
              >
                Update
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setModalOpen(true)}
                style={{
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                  padding: '4px 10px',
                }}
              >
                Delete
              </Button>
            </div>
          )}
          {(value === 1 || value === 2) && hasPermissions && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                // Instead of `type="submit" form="LandMapForm"`,
                // we do an onClick that forces the file input click
                onClick={() => {
                  const fileInput = document.getElementById('childFileInput');
                  if (fileInput) {
                    fileInput.click();
                  }
                }}
                style={{
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                  padding: '4px 10px',
                }}
              >
                Update
              </Button>
            </div>
          )}
          {(value === 3) && hasPermissions && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Uploader
                itemID={editableTract.id}
                updateFunction={(newData) => createTitleDoc({
                  ...newData,
                  tractID: editableTract.id  // Explicitly include tractID in mutation variables
                })}
                typeOfUploader={"TRACT"}
                history={history}
                setValue={setValue}
                setLoading={setLoading}
              />
              <AddIcon
                style={{fontSize: '30px',
                  color: '#fff',
                  float: 'right',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const plusDiv = document.getElementById('myChain');
                  if (plusDiv) {
                    plusDiv.click();
                  }
                }}
                fontSize="small" />
            </div>
          )}
        </Toolbar>
      </AppBar>

      <DeleteTractModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleAgree={handleDelete}
      />

      {value === 0 && (
        <TabContainer>
          <EditTractForm
            Tract={editableTract}
            history={history}
            EditTract={UpdateTract}
            DeleteTract={DeleteTract}
            updateMineralOwnership={updateMineralOwnership}
            updateSurfaceOwnership={updateSurfaceOwnership}
            snackbarControls={{ open: enqueueSnackbar, close: closeSnackbar }}
            submitValues={submitValues}
          />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <LandsLegal
            Tract={editableTract}
            EditTract={UpdateTract}
            mapUrl={Tract.countyMap}
            onSubmitMap={onSubmitMap}
            setNotification={enqueueSnackbar}
            mapType='countyMap'
          />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <LandsLegal
            Tract={editableTract}
            EditTract={UpdateTract}
            mapUrl={Tract.detailMap}
            onSubmitMap={onSubmitMap}
            setNotification={enqueueSnackbar}
            mapType='detailMap'
          />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer>
          <ChainOfTitleTable
            Tract={editableTract}
            queries={{
              createTitle: createTitleDoc,
              deleteTitle: deleteTitleDoc,
              editTitle: editTitleDoc,
            }}
            setValue={setValue}
            history={history}
            match={match}
          />
        </TabContainer>
      )}
      {value === 4 && (
        <TabContainer>
          <LandWellsTable Land={editableTract} />
        </TabContainer>
      )}
      {value === 5 && (
        <TabContainer>
          <LandROWTable Land={editableTract} />
        </TabContainer>
      )}
      {value === 6 && (
        <TabContainer>
          <OGLTable Tract={editableTract} />
        </TabContainer>
      )}
      {value === 7 && (
        <TabContainer>
          <OtherLeasesTable Tract={editableTract} />
        </TabContainer>
      )}

      <Typography variant='h5' className={classes.isAuthorized}>
        Not Authorized To Make Changes
      </Typography>
    </>
  );
}

export default EditTract;

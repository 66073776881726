import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import Auth from '../../util/Auth';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { UPLOAD_FILE } from '../../components/files/fileQueries';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const useStyles = makeStyles((theme) => ({
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '10px',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const LandMapandLegal = ({
                           Tract,
                           mapUrl,
                           mapType,
                           onSubmitMap,
                           setNotification,
                         }) => {
  const classes = useStyles();
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const handleFileUpload = async (e, setFieldValue, handleSubmit) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      // Upload to DO Spaces
      const response = await uploadFile({
        variables: {
          file,
          fileSize: String(file.size),
          name: file.name,
        },
      });

      // Extract the new filename & build URL
      const { filename } = response.data.singleUpload;
      const fileUrl = `https://monroe.nyc3.cdn.digitaloceanspaces.com/LAND_MACHINE/development/${filename}`;

      // Update the form field with the new URL
      setFieldValue(mapType, fileUrl);
      setNotification('File uploaded. Field updated!', { variant: 'success' });

      // Immediately submit the form, which calls onSubmit below
      handleSubmit();
    } catch (err) {
      console.error(err);
      setNotification('Upload failed', { variant: 'error' });
    }
  };

  return (
    <Formik
      initialValues={{
        ...Tract,
        [mapType]: mapUrl || '',
      }}
      onSubmit={(values, actions) => {
        // Once handleSubmit() is called, we come here to do the actual update
        onSubmitMap(values, actions, mapType);
      }}
    >
      {({ setFieldValue, handleSubmit }) => (
        <Form id="LandMapForm" className={classes.form}>
          {/* The text field showing the final URL (if user wants to see/edit it) */}
          <Field
            name={mapType}
            component={TextField}
            placeholder="Image URL"
            label="Image URL"
            className={classes.TextField}
            fullWidth
            hidden
          />

          {/* Hidden file input. We'll trigger this from the parent using document.getElementById('childFileInput').click() */}
          <input
            id="childFileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => handleFileUpload(e, setFieldValue, handleSubmit)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LandMapandLegal;

import React, { useState, useRef, useEffect } from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable, { MTableToolbar } from 'material-table';
import queryString from 'query-string';
import { getPageNumberFromQueryParams } from '../../util/Utils';


function replaceHistoryWithPage(pageNum, { history }) {
  history.replace(`/Operators?page=${pageNum}`);
}

const OperatorTable = ({ data, history }) => {
  const [pageNumber, setPageNumber] = useState(
    getPageNumberFromQueryParams(queryString.parse(window.location.search))
  );

  const tableRef = useRef(null);

  // Make sure pageSize is at least 1
  const calculatedPageSize = Math.max(
    1,
    Math.floor((window.innerHeight - 300) / 40)
  );

  useEffect(() => {
    if (tableRef.current?.dataManager) {
      tableRef.current.dataManager.changePageSize(calculatedPageSize);
    }
  }, [calculatedPageSize]);
  const columns = [
    {
      title: 'CONTACT NAME',
      field: 'name',
      render: (rowData) => (
        <Link
          to={`/Operators/${rowData.id}`}
          target='_blank'
          onClick={() => replaceHistoryWithPage(pageNumber, { history })}
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      title: 'TYPE',
      field: 'type',
    },
    {
      title: 'COMPANY WEBSITE',
      field: 'companyWebsite',
      render: (rowData) => (
        <Link to={`${rowData.companyWebsite}`} target='_blank' rel='noopener noreferrer'>
          {rowData.companyWebsite}
        </Link>
      ),
    },
    {
      title: 'OPERATOR NUMBER',
      field: 'operatorNumber',
    },
    {
      title: 'MAIN PHONE',
      field: 'phone',
      render: (rowData) => (rowData.phone ? rowData.phone : 'N/A'),
    },
    {
      title: 'MES #',
      field: 'mesAccountNumber',
      render: (rowData) => (rowData.mesAccountNumber ? rowData.mesAccountNumber : 'N/A'),
    },
    {
      title: 'MPI #',
      field: 'mpiAccountNumber',
      render: (rowData) => (rowData.mpiAccountNumber ? rowData.mpiAccountNumber : 'N/A'),
    }
  ];
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <MaterialTable
        tableRef={tableRef}
        data={data}
        columns={columns}
        title=''
        onChangePage={(page) => setPageNumber(page)}
        options={{
          tableLayout: 'auto',
          initialPage: pageNumber,
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#fff',
          },
          pageSize: calculatedPageSize,
          search: true,
          searchFieldStyle: {
            marginLeft: '-24px',
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
          maxBodyHeight: 'calc(100vh - 150px)',
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#1976d2',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                paddingBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MTableToolbar {...props} />
              <button
                onClick={() => history.push('/addOperator')}
                style={{
                  marginRight: '16px',
                  border: 'none',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  color: '#000',
                }}
              >
                Add Contact
              </button>
            </div>
          ),
        }}
      />
    </div>
  );
};



export default OperatorTable;
import React from 'react';
import Iframe from 'react-iframe';
import LandMapandLegal from './LandMapandLegal';

const LandsLegal = ({ Tract, EditTract, setNotification, mapUrl, mapType, onSubmitMap }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 20 }}>
      <div>
        <LandMapandLegal Tract={Tract} EditTract={EditTract} setNotification={setNotification} mapType={mapType} onSubmitMap={onSubmitMap} />
      </div>
      <div >
        <Iframe url={mapUrl} style={{ display: !mapUrl ? 'none' : 'block'}} />
      </div>
    </div>
  );
};

export default LandsLegal;

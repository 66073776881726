import gql from 'graphql-tag';

export const USERS_LIST = gql`
  query {
    Users
  }
`;
export const STATE_LIST = gql`
    query {
        states {
            id
            stateName
            counties {
                id
                name
            }
        }
    }
`;
export const ROLE_LIST = gql`
  query {
    __type(name: "UserRoleEnum") {
      enumValues {
        name
      }
    }
  }
`;

// RESOLVER MUTATION
export const SET_ROLE = gql`
  mutation SetRole($userID: ID!, $roles: [UserRoleEnum]) {
    setUserRole(userID: $userID, roles: $roles)
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($email: String!, $password: String!) {
    CreateUser(email: $email, password: $password)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userID: ID!) {
    DeleteUser(userID: $userID)
  }
`;
export const ADD_STATE = gql`
    mutation AddState($stateName: String!, $counties: [String]) {
        addState(stateName: $stateName, counties: $counties) {
            id
            stateName
            counties {
                id
                name
            }
        }
    }
`;
export const DELETE_STATE = gql`
    mutation deleteStateByIdentity($id: ID!) {
        deleteStateByIdentity(id: $id)
    }
`;
export const DELETE_COUNTY = gql`
    mutation deleteCountyByIdentity($id: ID!) {
        deleteCountyByIdentity(id: $id)
    }
`;

export const UPDATE_STATE = gql`
    mutation UpdateState($id: ID!, $stateName: String!, $counties: [String]) {
        updateState(id: $id, stateName: $stateName, counties: $counties) {
            id
            stateName
            counties {
                id
                name
            }
        }
    }
`;
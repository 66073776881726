import React from 'react';
import {Button} from "@material-ui/core";
import {read, utils} from "xlsx";
import {useSnackbar} from 'notistack';
function convertDate(number) {
    const date = new Date((number - (25567 + 2)) * 86400 * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

const Uploader = ({ itemID, updateFunction, typeOfUploader, setValue, setLoading }) => {
    const { enqueueSnackbar } = useSnackbar();

    const upload = (data) => {
        setLoading(true);
        Promise.all(data.map((row) => updateFunction(row))).then(() => {
            enqueueSnackbar('Title Run Uploaded', {variant: 'success'});
            setLoading(false);
            setValue(0);
        }).catch((err) => {
            enqueueSnackbar('Error: ' + err, {variant: 'error'});
        });
    }

    return (
        <div>
            <Button
                variant="contained"
                component="label"
                style={{
                    backgroundColor: '#ee7474',
                    color: 'white',
                    borderRadius: '20px',
                    padding: '4px 12px',
                    fontSize: '0.8rem',
                    minWidth: 'auto'
                }}
            >
                Upload
                <input
                    type="file"
                    hidden
                    accept=".xlsx"
                    onChange={(e) => {
                        try {
                            const reader = new FileReader();
                            reader.readAsBinaryString(e.target.files[0]);
                            reader.onload = (e) => {
                                const data = e.target.result;
                                const workbook = read(data, {type: 'binary'});
                                let sheetIndex = null;

                                Object.keys(workbook.Sheets).forEach((sheetName, index) => {
                                    const lowerCaseSheetName = sheetName.toLowerCase().replace(/\s/g, '');
                                    if (lowerCaseSheetName === 'titlerunupload') {
                                        sheetIndex = index;
                                    } else {
                                        enqueueSnackbar('Sheet name must be "TitleRunUpload"', {variant: 'error'});
                                    }
                                });

                                let finalData = [];

                                if (sheetIndex !== null) {
                                    enqueueSnackbar('Uploading Title Run', {variant: 'success'});
                                    const workSheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
                                    const sheet = utils.sheet_to_json(workSheet, {header: 1});
                                    const sheetData = sheet.map((row, index) => {
                                        if (index === 0) {
                                            return null;
                                        }
                                        const obj = {};
                                        row.forEach((value, index) => {
                                            obj[sheet[0][index]] = value;
                                        });
                                        return obj;
                                    }).filter((row) => row !== null);

                                    if (typeOfUploader === 'TRACT') {
                                        sheetData.forEach((row) => {
                                            sheetData[sheetData.indexOf(row)] = {
                                                volPage: row['Vol/Page']?.toString() || '',
                                                book: row['Book'] || '',
                                                comments: row['Comments'] || '',
                                                dateFiled: row['Date Filed'] || '',
                                                docType: row['Doc Type'] || '',
                                                effectiveDate: row['Effective Date'] || '',
                                                grantee: row['Grantee'] || '',
                                                grantor: row['Grantor'] || '',
                                                landDescription: row['Land Description'] || '',
                                                nmaTotal: row['NMA Total']?.toString() || '0',
                                                nsaTotal: row['NSA Total']?.toString() || '0',
                                                tractID: itemID,
                                            };
                                        });

                                        finalData = sheetData.filter((row) => row.volPage !== '');

                                        finalData.forEach((row) => {
                                            if (typeof row.effectiveDate !== 'string') {
                                                row.effectiveDate = convertDate(row.effectiveDate);
                                            }
                                            if (typeof row.dateFiled !== 'string') {
                                                row.dateFiled = convertDate(row.dateFiled);
                                            }
                                        });

                                        upload(finalData);
                                    } else if (typeOfUploader === 'ROW') {
                                        sheetData.forEach((row) => {
                                            sheetData[sheetData.indexOf(row)] = {
                                                volPage: row['Vol/Page']?.toString() || '',
                                                dateFiled: row['Date Filed'] || '',
                                                effectiveDate: row['Effective Date'] || '',
                                                grantor: row['Grantor'] || '',
                                                grantee: row['Grantee'] || '',
                                                comments: row['Comments'] || '',
                                            };
                                        });

                                        finalData = sheetData.filter((row) => row.volPage !== '');

                                        finalData.forEach((row, index) => {
                                            if (typeof row.effectiveDate !== 'string') {
                                                row.effectiveDate = convertDate(row.effectiveDate);
                                            }
                                            if (typeof row.dateFiled !== 'string') {
                                                row.dateFiled = convertDate(row.dateFiled);
                                            }
                                            finalData[index] = {variables: {ROWID: itemID, rowinfo: finalData[index]}};
                                        });
                                        upload(finalData)
                                    } else if (typeOfUploader === 'UNIT') {
                                        // Handle UNIT uploader logic here
                                        sheetData.forEach((row) => {
                                            sheetData[sheetData.indexOf(row)] = {
                                                volPage: row['Vol/Page']?.toString() || '',
                                                docType: row['Doc Type'] || '',
                                                effectiveDate: row['Effective Date'] || '',
                                                dateFiled: row['Date Filed'] || '',
                                                grantor: row['Grantor'] || '',
                                                grantee: row['Grantee'] || '',
                                                comments: row['Comments'] || '',
                                            };
                                        });

                                        finalData = sheetData.filter((row) => row.volPage !== '');

                                        finalData.forEach((row, index) => {
                                            if (typeof row.effectiveDate !== 'string') {
                                                row.effectiveDate = convertDate(row.effectiveDate);
                                            }
                                            if (typeof row.dateFiled !== 'string') {
                                                row.dateFiled = convertDate(row.dateFiled);
                                            }
                                            finalData[index] = {variables: {UnitID: itemID, info: finalData[index]}};
                                        });
                                        upload(finalData)
                                    } else {
                                        enqueueSnackbar('Error: No sheet found named "TitleRunUpload"', {variant: 'error'});
                                    }
                                }
                            }
                        } catch (error) {
                            // Handle any errors that occurred during the process
                            console.error('An error occurred:', error);
                            // Display an error message to the user
                            enqueueSnackbar('An error occurred while processing the file', { variant: 'error' });
                        }
                    }}
                />
            </Button>
        </div>
    );
};

export default Uploader;